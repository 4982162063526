import React, { useEffect } from 'react';
import { useAppContext } from 'core/hooks';
import { Spinner } from 'core/components';

function Logout() {
  const { authorizer, history } = useAppContext();

  useEffect(() => {
    authorizer.logout((history as any).location.state);
  }, [authorizer, history]);

  return (
    <Spinner light={true} ratio={3} position="center" />
  );
}

export default Logout;
