import { getAppContextState } from 'AppContext';

export function getActions<T=any>(name: string): T {
  const { registrar } = getAppContextState();
  return registrar.getActions<T>(name);
}

export function getHelpers<T=any>(name: string): T {
  const { registrar } = getAppContextState();
  return registrar.getHelpers<T>(name);
}

export function getComponents<T=any>(name: string): T {
  const { registrar } = getAppContextState();
  return registrar.getComponents<T>(name);
}

export function toModel<T=any>(obj: any) {
  const { registrar } = getAppContextState();
  return registrar.toModel<T>(obj);
}
