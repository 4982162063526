import { useMemo } from 'react';
import { useStoreSelector } from 'core/hooks';
import { Selector, EqualityFunction } from 'core';
import { toModel } from 'core/utils';

export default function useStoreModelSelector<ModelType=any>(sectionKey: string, selector: Selector, equalityFn?: EqualityFunction): ModelType {
  const state = useStoreSelector(sectionKey, selector, equalityFn);
  const model = useMemo(() => toModel<ModelType>(state), [state]);
  return model as ModelType;
}
