import { EventEmitter } from 'fbemitter';

export type Listener = (payload: Record<string, any>) => void;
const emitter = new EventEmitter();

export function addEmbeddedMessageHandler(messageType: string, listener: Listener) {
  const token = emitter.addListener(messageType, listener);
  return () => token.remove();
}

export function sendEmbeddedMessage(messageType: string, payload: Record<string, any>) {
  const sendEmbeddedMessage = (window as any).sendEmbeddedMessage;
  if (sendEmbeddedMessage) {
    sendEmbeddedMessage(messageType, payload);
  }
}

export function embeddedMessagePublisher(messageType: string, payload: string) {
  emitter.emit(messageType, JSON.parse(payload));
}

window.addEventListener('unload', function (e) {
  sendEmbeddedMessage('unload', {});
});