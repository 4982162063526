import React from 'react';
import { useTranslator } from 'core/hooks';
import { navigate } from 'core/utils';

function TimeSkewed() {
  const t = useTranslator();

  function onReload() {
    navigate({ pathParams: ['jobmanager'] });
  }

  return (
    <div className="uk-position-center uk-display-inline-block" style={{ width: "75%" }}>
      <p className="uk-light uk-text-light uk-text-center" style={{ whiteSpace: "pre-line" }}>{t('core.message.requestTimeTooSkewedMessage')}</p>
      <div className="uk-flex uk-flex-center uk-margin-top">
        <button type="button" className="uk-button uk-button-secondary" onClick={onReload}>
          {t('core.label.reloadPage')}
        </button>
      </div>
    </div>
  );
}

export default TimeSkewed;
