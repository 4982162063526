import React from 'react';

function Footer() {
  return (
    <footer className="uk-light uk-padding-small">
    </footer>
  );
}

export default Footer;
