import React, { useState, useEffect } from 'react';
import { useAppContext, useTranslator } from 'core/hooks';
import { navigateUp, getPathParams } from 'core/utils';
import { ReactComponent as HomeButtonIcon } from 'afx/image/homeHome64.svg';
import { useMediaQuery } from '@react-hook/media-query';
import { smallWidthMediaQuery } from 'core/components';

function shouldShowHomeButton() {
  return getPathParams().length > 1;
}

function HomeButton() {
  const { history } = useAppContext();
  const [showHomeButton, setShowHomeButton] = useState<boolean>(shouldShowHomeButton());
  const t = useTranslator();
  const smallWidth = useMediaQuery(smallWidthMediaQuery);

  useEffect(() => {
    return history.listen(() => {
      setShowHomeButton(shouldShowHomeButton());
    });
  }, [history]);

  function goBack() {
    navigateUp();
  }

  if (!showHomeButton) {
    return null;
  }

  const size = smallWidth ? 18 : 24;
  return (
    <button
      type="button"
      className="uk-button uk-button-text"
      data-testid="home-button"
      onClick={goBack}>
      <HomeButtonIcon width={size} height={size} data-uk-tooltip={t('core.label.home')}/>
    </button>
  );
}

export default HomeButton;