import React, { useState, MouseEvent } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { useAppContext } from 'core/hooks';
import { ReactComponent as StarLogoIcon } from 'core/image/starlogo.svg';
import { ReactComponent as UserIcon } from 'afx/image/cmdUser24.svg';
import Subnavigation from './Subnavigation';
import HomeButton from './HomeButton';
import { smallWidthMediaQuery } from 'core/components';

function Navigation() {
  const { authorizer } = useAppContext();
  const [showSubnav, setShowSubnav] = useState<boolean>(false);
  const smallWidth = useMediaQuery(smallWidthMediaQuery);
  const { toggleDeveloperMode } = useAppContext();

  function onToggleSubnavigation(event: MouseEvent) {
    event.nativeEvent.stopImmediatePropagation()
    event.stopPropagation();
    setShowSubnav(!showSubnav);
  }

  function onMenuButtonClick(event: MouseEvent) {
    if (event.altKey && event.metaKey) {
      toggleDeveloperMode();
    } else {
      onToggleSubnavigation(event);
    }
  }

  if (!authorizer.isUserAuthenticated()) {
    return (
      <div className="navigation uk-margin-small-right">
      </div>
    );
  }

  if (smallWidth) {
    return (
      <div className="navigation uk-light" style={{ marginRight: 5 }}>
        <div className="uk-position-top-left" style={{ minWidth: 30, paddingTop: 13, paddingLeft: 7 }}>
          <button
            className="uk-button uk-button-text"
            data-uk-icon="menu"
            onClick={onMenuButtonClick}
          />
        </div>
        {showSubnav && <Subnavigation mobile={true} onHide={() => setShowSubnav(false)}/>}
      </div>
    );
  } else {
    return (
      <div className="navigation uk-light uk-flex">
        <div className="main-navigation uk-padding-small uk-flex uk-flex-column uk-flex-between">
          <TopNavigation />
          <BottomNavigation onToggleSubnavigation={onToggleSubnavigation} />
        </div>
        {showSubnav && <Subnavigation onHide={() => setShowSubnav(false)}/>}
      </div>
    );
  }
}

function TopNavigation() {
  const { toggleDeveloperMode } = useAppContext();

  function onLogoClick(event: MouseEvent) {
    if (event.altKey && event.metaKey) {
      toggleDeveloperMode();
    }
  }

  return (
    <div>
      <div className="star-logo" onClick={onLogoClick}>
        <StarLogoIcon width={32} height={32} />
      </div>
      <ul className="uk-list uk-text-center uk-margin-top">
        <li><HomeButton /></li>
      </ul>
    </div>
  );
}

function BottomNavigation({ onToggleSubnavigation }: any) {
  return (
    <ul className="uk-iconnav uk-iconnav-vertical uk-text-center">
      <li>
        <div className="user-button-wrapper">
          <button
            type="button"
            className="uk-button uk-button-primary uk-flex uk-flex-center uk-flex-middle uk-preserve-color user-button"
            onClick={onToggleSubnavigation}>
            <UserIcon width={24} height={24} />
          </button>
        </div>
      </li>
    </ul>
  );
}

export default Navigation;
