import { ApolloClient, DefaultOptions } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from "apollo-link-retry";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getApiEndpoint } from '../utils';

export type GraphQlClient = ApolloClient<any>;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

type ConfigOptions = {
  fetch?: WindowOrWorkerGlobalScope['fetch'],
};

export function createGraphQLClient({ fetch }: ConfigOptions): GraphQlClient {
  const httpLink = new HttpLink({
    uri: getApiEndpoint(),
    fetch,
  });

  const retryLink = new RetryLink({
    delay: {
      jitter: false,
    },
    attempts: {
      max: 7,
    },
  });

  const link = ApolloLink.from([retryLink, httpLink]);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions,
    queryDeduplication: false,
  });

  (window as any).__APOLLO_CLIENT__ = client;
  return client;
}
