import { ActionWithPayload, Selector, EqualityFunction } from 'core';
import * as schema from 'core/graphql/schema';
import { useStoreSelector as coreStoreSelectorHook } from 'core/hooks';
import { getActions as coreGetActions, getComponents as coreGetComponents, isEmbedded } from 'core/utils';

export const key = 'fileTransfer';

export type FrsFileRepositoryTicketFileKey = {
  filename: string,
  key: string,
}

export type UploadableTicketFile = FrsFileRepositoryTicketFileKey & {
  file: File;
};

export type DownloadableTicketFile = FrsFileRepositoryTicketFileKey & {
  url: string;
}

export type FrsFileRepositoryTickets = schema.IssueFrsFileRepositoryTicketsResult & {
  repositoryId: string;
};

export type FrsFileRepositoryUploadTickets = FrsFileRepositoryTickets & {
  uploadableTicketFiles: UploadableTicketFile[];
};

export type FrsFileRepositoryDownloadTickets = FrsFileRepositoryTickets & {
  downloadableTicketFiles: DownloadableTicketFile[];
};

export type State = {
  fileRepositoryTickets: {
    uploads: Record<string, FrsFileRepositoryUploadTickets>,
    downloads: Record<string, FrsFileRepositoryDownloadTickets>,
  };
  uploads: Record<string, any>;
};

export interface Actions {
  issueFrsFileRepositoryUploadTickets(repositoryId: string, files: File[]): Promise<ActionWithPayload>;
  issueFrsFileRepositoryDownloadTickets(repositoryId: string, filenames: string[]): Promise<ActionWithPayload>;
  uploadFiles(input: { repositoryId: string, metadata?: Record<string, any> }): Promise<void>;
  cancelFileUploads(input: { repositoryId: string, metadata?: Record<string, any> }): void;
}

export interface Components {
  FileTransferMonitor(props: { files: File[] }): JSX.Element;
}

export function useSelector<SelectedStateType=any>(selector: Selector<State, SelectedStateType>, equalityFn?: EqualityFunction): SelectedStateType {
  return coreStoreSelectorHook<SelectedStateType>(key, selector, equalityFn);
}

export function getActions(): Actions {
  return coreGetActions<Actions>(key);
}

export function getComponents(): Components {
  return coreGetComponents<Components>(key);
}

export enum ActionType {
  issueFrsFileRepositoryTickets = 'fileTransfer/issueFrsFileRepositoryTickets',
  updateFileUploadStatus = 'fileTransfer/updateFileUploadStatus',
};

export const hasFileTransferManager = isEmbedded();
