import React, { useState } from 'react';

function ErrorInfo({ error }: { error : Error }) {
  const [showErrorContent, setShowErrorContent] = useState<boolean>(false);
  const action = (error as any).action;
  return (
    <div>
      <div className="uk-margin-top">
        <button
          className="uk-button uk-button-text uk-position-relative"
          style={{ left: -20 }}
          onClick={() => setShowErrorContent(!showErrorContent)}
          data-uk-icon={showErrorContent ? 'triangle-down' : 'triangle-right'}></button>
        {showErrorContent &&
          <pre id="error-content" className="uk-margin-small-top" style={{ maxHeight: 300 }}>
            <code>{action ? JSON.stringify(action, null, 2) : error.message}</code>
          </pre>
        }
      </div>
    </div>
  );
}

export default ErrorInfo;
