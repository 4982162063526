import React from 'react';
import { Authorizer, Store, Registrar } from 'core';
import { History } from 'history';
import { createGraphQLClient, GraphQlClient } from 'core/graphql';
import { getAuthEndpoint, getAuthClientId, createHistory } from 'core/utils';

export type AppContextState = {
  history: History,
  authorizer: Authorizer,
  graphQLClient: GraphQlClient,
  store: Store,
  registrar: Registrar,
  developerMode: boolean,
  toggleDeveloperMode: () => void,
  error?: Error,
};

const history = createHistory();
const authorizer = new Authorizer({ history, authEndpoint: getAuthEndpoint(), clientId: getAuthClientId() });
const graphQLClient = createGraphQLClient({ fetch: authorizer.authenticatedFetch });
const store = new Store();
const registrar = new Registrar();

const intialContextState = {
  history,
  authorizer,
  graphQLClient,
  store,
  registrar,
  developerMode: false,
  toggleDeveloperMode: () => {},
};

const AppContext = React.createContext<AppContextState>(intialContextState);

export function getAppContextState(): AppContextState {
  return (AppContext as any)._currentValue;
}

export default AppContext;
