import React, { ReactNode, Fragment } from 'react';
import ReactDOM from 'react-dom';
import UIkit from 'uikit';

type ModalActionProps = {
  label: ReactNode | string,
  onClick: () => void,
  className?: string,
  cancelable?: boolean,
};

type ModalProps = {
  component?: ReactNode,
  title?: ReactNode | string,
  content?: ReactNode,
  body?: ReactNode | string,
  footer?: ReactNode | string,
  action?: ModalActionProps,
  alternateAction?: ModalActionProps,
  onClose?: () => void,
  background?: string,
};

function Modal({ title, content, body, footer, action, alternateAction, onClose }: ModalProps) {
  return (
    <div className="uk-modal-dialog" data-testid="modal-dialog">
      {(!action || action.cancelable) && <button type="button" className="uk-modal-close-default" data-uk-close onClick={onClose}></button>}
      <div className="uk-modal-header">
        <h2 className="uk-modal-title">{title}</h2>
      </div>
      {content && content}
      {body &&
        <div className="uk-modal-body">
          {typeof body === 'string' ? <p>{body}</p> : body}
        </div>
      }
      <div className="uk-modal-footer uk-text-right">
        {(action || footer) ?
          (action ? <ModalAction {...action} /> : footer) :
          <button
            type="button"
            className="uk-button uk-button-primary uk-modal-close"
            data-testid="modal-close-button"
            onClick={onClose}>
            Close
          </button>
        }
        {alternateAction && <ModalAlternateAction {...alternateAction} />}
      </div>
    </div>
  );
}

export function ModalAction({ label, onClick, className, cancelable }: ModalActionProps) {
  return (
    <Fragment>
      {cancelable &&
        <button
          type="button"
          className="uk-button uk-button-default uk-modal-close"
          data-testid="modal-cancel-button">
          Cancel
        </button>
      }
      <button
        type="button"
        className={`uk-button uk-modal-close uk-margin-small-left ${className || 'uk-button-primary'}`}
        onClick={onClick}
        data-testid="modal-action-button">
        {label}
      </button>
    </Fragment>
  );
}

export function ModalAlternateAction({ label, onClick, className }: ModalActionProps) {
  return (
    <Fragment>
      <button
        type="button"
        className={`uk-button uk-modal-close uk-margin-small-left ${className || 'uk-button-primary'}`}
        onClick={onClick}
        data-testid="modal-alternate-action-button">
        {label}
      </button>
    </Fragment>
  );
}

let modalElement: HTMLElement | undefined = undefined;

Modal.show = (props: ModalProps) => {
  if (Modal.isShowing()) {
    return;
  }

  modalElement = document.createElement('DIV');
  modalElement.setAttribute('uk-modal', 'true');
  if (props.background) {
    modalElement.style.background = props.background;
  }

  UIkit.util.once(modalElement, 'hide', (event: Event) => {
    const elem = event.target;
    (elem as any).__hiding = true;
  });

  UIkit.util.once(modalElement, 'hidden', (event: Event) => {
    const elem = event.target as HTMLElement;
    ReactDOM.unmountComponentAtNode(elem);
    elem.remove();
    if (elem === modalElement) {
      modalElement = undefined;
    }
  });

  const component = props.component || <Modal {...props} />;
  ReactDOM.render(component as any, modalElement);
  UIkit.modal(modalElement, { bgClose: false }).show();
}

Modal.hide = () => {
  if (Modal.isShowing()) {
    UIkit.modal(modalElement).hide();
  }
}

Modal.isShowing = () => {
  return modalElement && !(modalElement as any).__hiding;
}

export default Modal;
