export type Plugin = {
  name: string,
};

const pluginRegistry: Plugin[] = [
  {
    name: 'jobmanager',
  },
  {
    name: 'playground',
  },
];

export default pluginRegistry;
