import React from 'react';

type SpinnerProps = {
  ratio?: number,
  position?: string,
  light?: boolean,
};

function Spinner({ ratio, position, light }: SpinnerProps = {}) {
  const options = `ratio: ${ratio || 1}`;
  const positionClass = position ? `uk-position-${position}` : '';
  const lightClass = light ? 'uk-light' : '';
  const classes = [lightClass, positionClass].join(' ');
  return (
    <span className={classes} data-uk-spinner={options} />
  );
}

export default Spinner;
