import { useState, useCallback, useEffect, useRef } from 'react';
import { Action, ActionDispatcher } from 'core';
import { useAppContext } from 'core/hooks';
import useInterval from './useInterval';
import { ModalSpinner } from 'core/components';
import { isEmpty } from 'lodash';

export enum UseActionBlockUI {
  Never,
  OnMount,
  Always,
}

export type UseActionOptions = {
  autoInvoke?: boolean,
  interval?: number,
  blockUI?: boolean | UseActionBlockUI,
  returnStatus?: boolean,
}

export type UseActionStatus = {
  pending?: boolean,
  error?: Error,
  action?: Action,
  blockUI?: UseActionBlockUI,
}

export type UseActionReturnType = UseActionStatus & {
  invoker: () => void,
}

export default function useAction(actionDispatcher: ActionDispatcher, { autoInvoke, interval, blockUI, returnStatus }: UseActionOptions): UseActionReturnType {
  const savedActionDispatcher = useRef<ActionDispatcher>(actionDispatcher);
  const [status, setStatus] = useState<UseActionStatus>({});
  const context = useAppContext();
  const updateStatus = returnStatus || autoInvoke;

  // Remember the latest action dispatcher.
  useEffect(() => {
    if (blockUI) {
      savedActionDispatcher.current = ModalSpinner.wrapFunction(async () => {
        const action = await Promise.resolve(actionDispatcher());
        if (blockUI === UseActionBlockUI.OnMount) {
          savedActionDispatcher.current = actionDispatcher;
        }
        return action;
      });
    } else {
      savedActionDispatcher.current = actionDispatcher;
    }
  }, [actionDispatcher, blockUI]);

  // This is the invoker that actually calls the given action dispatcher.
  const invoker = useCallback(async () => {
    if (context.error || status.pending) {
      return;
    }

    if (updateStatus) {
      setStatus({ pending: true, error: status.error });
    }
    try {
      if (updateStatus) {
        const action = await Promise.resolve(savedActionDispatcher.current());
        setStatus({ action });
      } else {
        savedActionDispatcher.current();
      }
    } catch (e) {
      console.error(e);
      e.action && console.error(JSON.stringify(e.action, null, 2));
      setStatus({ error: e });
    }
  }, [savedActionDispatcher, updateStatus, status, context]);

  // Make sure the invoker is called once initially if autoInvoke is set.
  useEffect(() => {
    if (autoInvoke && isEmpty(status)) {
      invoker();
    }
  }, [invoker, autoInvoke, status]);

  // If autoInvoke is set, and an interval is given, keep invoking the action at the given interval.
  useInterval(invoker, autoInvoke ? interval : 0);

  return { invoker, ...status };
}
