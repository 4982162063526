import { compact } from 'lodash';
import { createBrowserHistory, History } from 'history';
import UIkit from 'uikit';

let appExitBlockCount = 0;
let history: History;

type NavigateInput = {
  pathParams?: string[],
  searchParams?: Record<string, string>,
  state?: Record<string, any>,
  replace?: boolean,
};

window.addEventListener('beforeunload', function (e) {
  if (appExitBlockCount === 0) {
    return undefined;
  }
  const confirmationMessage = 'Changes you made may not be saved.'; // This text is not used by newer browsers.
  (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
});

export function createHistory() {
  const publicUrl = normalizePath(process.env.PUBLIC_URL);
  const { basename, pathname } = getBasenameAndPathname();
  if (basename && basename !== publicUrl) {
    navigateToProperVersion(basename, pathname);
  }

  history = createBrowserHistory({ basename });

  const searchParams = getSearchParams();
  if (searchParams.has('pathname')) {
    navigateToPathnameInSearchParams(searchParams);
  }

  return history;
}

export function getPublicUrl() {
  return process.env.PUBLIC_URL;
}

export function getPathParams() {
  const pathParams = history.location.pathname.split('/') as string[];
  return compact(pathParams).map(decodeURIComponent);
}

export function getSearchParams() {
  const search = history.location.search;
  return new URLSearchParams(search);
}

export function navigate({ pathParams, searchParams, state, replace}: NavigateInput) {
  const search = searchParams ? '?' + Object.entries(searchParams).map(([key, value]: string[]) =>
    `${key}=${encodeURIComponent(value)}`).join('&') : history.location.search;

  const pathname = pathParams ? '/' + pathParams.map(encodeURIComponent).join('/') : history.location.pathname

  if (replace) {
    history.replace(`${pathname}${search}`, state);
  } else {
    history.push(`${pathname}${search}`, state);
  }
}

export function navigateUp({ replace }: any = {}) {
  const pathParams = getPathParams();
  if (pathParams.length > 1) {
    pathParams.pop();
    navigate({ pathParams, replace });
  }
}

export function navigateDown({ path, replace }: any = {}) {
  const pathParams = getPathParams();
  pathParams.push(path);
  navigate({ pathParams, replace });
}

export async function withAppExitBlocked(message: string, func: () => Promise<void>) {
  appExitBlockCount++;
  if (message) {
    UIkit.notification.closeAll();
    UIkit.notification(message, {status: 'primary', timeout: 0 });
  }
  try {
    await func();
  } finally {
    appExitBlockCount--;
    if (appExitBlockCount === 0) {
      UIkit.notification.closeAll();
    }
  }
}

function normalizePath(path: string) {
  return path?.startsWith('/') ? path.slice(1) : path;
}

function getBasenameAndPathname() {
  const pathname = window.location.pathname;
  const versionMatch = pathname.match(/\/\d+\.\d+\.\d+/);
  if (versionMatch) {
    const parts = pathname.split(versionMatch[0]);
    const basename = parts[0].slice(1) + versionMatch[0];
    const rest = parts[1] && parts[1].slice(1);
    return { basename, pathname: rest };
  } else {
    return { basename: '', pathname };
  }
}

function navigateToProperVersion(basename: string, pathname: string) {
  const searchParams = new URLSearchParams(window.location.search);
  if (pathname) {
    searchParams.append('pathname', pathname);
  }
  let search = searchParams.toString();
  if (search) {
    search = '?' + search;
  }
  window.location.href = `${window.location.origin}/${basename}${search}`;
}

function navigateToPathnameInSearchParams(searchParams: URLSearchParams) {
  const pathname = searchParams.get('pathname');
  searchParams.delete('pathname');
  let search = searchParams.toString();
  if (search) {
    search = '?' + search;
  }
  history.replace(`${pathname}${search}`);
}


export function isAppExitBlocked() {
  return appExitBlockCount > 0;
}

export function forceLogout() {
  appExitBlockCount = 0;
  window.location.href = window.location.origin + `${getPublicUrl()}/logout`;
}
