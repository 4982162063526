import { Action, ActionWithPayload, State } from 'core/Store';
import { denormalizeGraphQLData } from 'core/graphql';
import { getAppContextState } from 'AppContext';

export function getActionPayload<T=Record<string, any>>(action: Action): T {
  return (action as ActionWithPayload).payload as T;
}

export function getStoreState<T=State>(path: string = '', denormalize: boolean = true): T {
  const { store} = getAppContextState();
  const state = getExpandedPathState(store.getState(), path);
  return denormalize ? denormalizeGraphQLData(state) : state;
}

export function getExpandedPathState(state: State, path: string): any {
  if (path) {
    const parts = path.split('.');
    let nestedState = state[parts.shift() as string];
    while (nestedState && parts.length > 0) {
      nestedState = nestedState[parts.shift() as string];
      if (typeof nestedState === 'string' && nestedState.startsWith('@')) {
        path = [nestedState.slice(1), ...parts].join('.');
        return getExpandedPathState(state, path);
      }
    }
    return nestedState;
  } else {
    return state;
  }
}
