import { ActionWithPayload, Selector, EqualityFunction } from 'core';
import * as schema from 'core/graphql/schema';
import * as hooks from 'core/hooks';
import * as utils from 'core/utils';

export {
  ComputeJobStatus,
  ComputeTaskStatus,
  ComputeTaskUnifiedStatus,
  FileRepositoryStatus,
} from 'core/graphql/schema';

export const key = 'jobManager';

export type TaskApplication = {
  name: string;
  cmdOptions: string[];
};

export type NewJobSubmissionConfig = {
  jobName?: string;
  submissionTemplateId?: string;
  simulationFile?: File;
  macroFile?: File;
  auxiliaryFiles?: File[];
};

export type NewJobSubmission = {
  id: string;
  config: NewJobSubmissionConfig;
  status: NewJobSubmissionStatus;
  computeJobId?: string;
  error?: Error;
  errorReported?: boolean;
};

export type Workspace = schema.Workspace;
export type SubmissionTemplate = schema.SubmissionTemplate;
export type FrsFileRepositoryFile = schema.FrsFileRepositoryFile;
export type FileRepository = schema.FileRepository;
export type FrsFileRepository = schema.FrsFileRepository;
export type ComputeJob = schema.ComputeJob;
export type ComputeTask = schema.ComputeTask;

export interface SubmissionTemplateWrapper {
  template: SubmissionTemplate;
  isDevTestingTemplate(): boolean;
  getLabel(): string;
  getInfo(): string;
  getRate(): string;
}
export type SubmissionTemplateModel = SubmissionTemplate & SubmissionTemplateWrapper;

export interface ComputeTaskWrapper {
  task: ComputeTask;
  isRunning(): boolean;
  isStopped(): boolean;
}
export type ComputeTaskModel = ComputeTask & ComputeTaskWrapper;

export interface ComputeJobWrapper {
  job: ComputeJob;
  isFake(): boolean;
  isSubmitting(): boolean;
  isRunning(): boolean;
  isStopped(): boolean;
  isCanceled(): boolean;
  canBeTerminated(): boolean;
  canBeDeleted(): boolean;
  getNewJobSubmissionForJob(): NewJobSubmission | undefined;
  getUnifiedStatusString(): string;
  getSubmissionTemplate(): SubmissionTemplateModel | undefined;
  getCost(): number;
}
export type ComputeJobModel = ComputeJob & ComputeJobWrapper;

export type State = Record<string, any> & {
  newJobSubmissions: Record<string, NewJobSubmission>;
};

export interface Actions {
  queryWorkspace(): Promise<ActionWithPayload>;
  queryComputeJobStatus(input: { computeJobId: string }): Promise<ActionWithPayload>;
  queryComputeTaskFileRepositories(input: { computeTaskId: string }): Promise<ActionWithPayload>;
  queryComputeTaskLog(input: { computeTaskId: string, after: string, first: number }): Promise<ActionWithPayload>;
  queryComputeTaskLogFromEnd(input: { computeTaskId: string, before: string, last: number }): Promise<ActionWithPayload>;
  queryUserBalance(): Promise<ActionWithPayload>;
  createComputeJob(input: { name?: string, submissionTemplateId: string }): Promise<ActionWithPayload>;
  createComputeTask(input: { computeJobId: string, name?: string, application: TaskApplication }): Promise<ActionWithPayload>;
  submitComputeJob(input: { computeJobId: string }): Promise<ActionWithPayload>;
  terminateComputeJob(input: { computeJobId: string }): Promise<ActionWithPayload>;
  deleteComputeJob(input: { computeJobId: string }): Promise<ActionWithPayload>;
  createNewJobSubmission(input: { config: NewJobSubmissionConfig }): string;
  executeNewJobSubmission(input: {id: string, config: NewJobSubmissionConfig}): Promise<void>;
  updateNewJobSubmission(payload: Record<string, any>): ActionWithPayload;
}

export interface Helpers {
  haveWorkspace(): boolean;
}

export function useSelector<SelectedStateType=any>(selector: Selector<State, SelectedStateType>, equalityFn?: EqualityFunction): SelectedStateType {
  return hooks.useStoreSelector<SelectedStateType>(key, selector, equalityFn);
}

export function useModelSelector<ModelType=any>(selector: Selector<State>): ModelType {
  return hooks.useStoreModelSelector<ModelType>(key, selector);
}

export function getState<T=State>(path: string = ''): T {
  return utils.getStoreState<T>(`${key}.${path}`);
}

export function getActions(): Actions {
  return utils.getActions<Actions>(key);
}

export function getHelpers(): Helpers {
  return utils.getHelpers<Helpers>(key);
}

export enum ActionType {
  queryWorkspace = 'jobManager/queryWorkspace',
  createComputeJob = 'jobManager/createComputeJob',
  createComputeTask = 'jobManager/createComputeTask',
  submitComputeJob = 'jobManager/submitComputeJob',
  terminateComputeJob = 'jobManager/terminateComputeJob',
  deleteComputeJob = 'jobManager/deleteComputeJob',
  queryComputeJobStatus = 'jobManager/queryComputeJobStatus',
  queryComputeTaskLog = 'jobManager/queryComputeTaskLog',
  queryComputeTaskLogFromEnd = 'jobManager/queryComputeTaskLogFromEnd',
  createNewJobSubmission = 'jobManager/createNewJobSubmission',
  updateNewJobSubmission = 'jobManager/updateNewJobSubmission',
  queryComputeTaskFileRepositories = 'jobManager/queryComputeTaskFileRepositories',
  queryUserBalance = 'jobManager/queryUserBalance',
}

export enum NewJobSubmissionStatus {
  None,
  Canceled,
  Error,
  Creating,
  Uploading,
  Submitting,
  Complete,
}

export const FAILED_JOB_SUBMISSIONS_KEY = 'failedJobSubmissions';

export class AbortedJobSubmissionError extends Error {
  public code: string = 'AbortedJobSubmissionError';
  constructor() {
    super('Job submission aborted.');
  }
}

export class UploadFailedError extends Error {
  public code: string = 'UploadFailedError';
  public reason: Error;
  constructor(error: Error) {
    super('Upload of sim file failed.');
    this.reason = error;
  }
}

const NEW_JOB = 'newjob';

export function createNewJobPath() {
  return `${NEW_JOB}${Date.now()}`;
}

export function isNewJob(path: string) {
  return path.startsWith(NEW_JOB);
}
