import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Selector, EqualityFunction } from 'core';
import { denormalizeGraphQLData } from 'core/graphql';
import { getExpandedPathState } from 'core/utils';

export default function useGraphQLSelector<SelectedStateType=any>(sectionSelector: Selector, equalityFn?: EqualityFunction): SelectedStateType {
  const selector = useMemo(() => {
    return typeof sectionSelector === 'string' ?
      (state: any) => getExpandedPathState(state, `graphql.${sectionSelector}`) :
      (state: any) => sectionSelector(state.graphql);
  }, [sectionSelector]);

  const state = useSelector(selector, equalityFn);
  return state ? denormalizeGraphQLData(state) : state;
}
