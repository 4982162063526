import React, { useState, MouseEvent } from 'react';
import { ReactComponent as Logo } from 'afx/image/miscSiemensLogo24.svg';
import { getPublicUrl, getAppserverEndpoint } from 'core/utils';
import { useMediaQuery } from '@react-hook/media-query';
import { smallWidthMediaQuery, HomeButton } from 'core/components';

const version = getPublicUrl() || 'development';

function Header() {
  const [showVersion, setShowVersion] = useState<boolean>(false);
  const smallWidth = useMediaQuery(smallWidthMediaQuery);

  function onTitleClick(event: MouseEvent) {
    if (event.altKey && event.metaKey) {
      setShowVersion(!showVersion);
    }
  }

  const hClass = smallWidth ? 'uk-h4' : '';
  return (
    <header className="uk-light uk-padding-small uk-position-relative" style={{ paddingLeft: 0, marginLeft: smallWidth ? 25 : 0 }}>
      {smallWidth && <div className="uk-display-inline uk-position-relative" style={{ top: -2, marginRight: 5 }}><HomeButton /></div>}
      <h2 className={`uk-display-inline uk-position-relative ${hClass}`} style={{ top: -1, lineHeight: '1em' }} onClick={onTitleClick}>
        {process.env.REACT_APP_NAME}
      </h2>
      {showVersion && <span className="uk-margin-small-left">{`${version}, appserver=${getAppserverEndpoint()}`}</span>}
      <Logo className="uk-float-right" width={smallWidth ? 58: 80} height={smallWidth ? 13 : 18} />
    </header>
  );
}

export default Header;
