import { useEffect, useRef } from 'react';

type Callback = (args?: any) => void;

function useInterval(callback: Callback, interval: number | null | undefined) {
  const savedCallback = useRef<Callback>(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (interval) {
      let id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval]);
}

export default useInterval;
