import { last } from 'lodash';

const searchParams = new URLSearchParams(window.location.search);
let appserverParam = searchParams.get('appserver');
if (searchParams.has('code')) {
  const state = JSON.parse(searchParams.get('state') || '{}');
  if (state.appserver) {
    appserverParam = state.appserver;
  }
}

const WMC_BRANCH_STORAGE_KEY = 'WmcBranch';
let wmcbranchParam = searchParams.get('wmcbranch');
if (wmcbranchParam) {
  window.sessionStorage.setItem(WMC_BRANCH_STORAGE_KEY, wmcbranchParam);
} else if (searchParams.has('code')) {
  wmcbranchParam = window.sessionStorage.getItem(WMC_BRANCH_STORAGE_KEY);
} else {
  window.sessionStorage.removeItem(WMC_BRANCH_STORAGE_KEY);
}

export function getAppEnv(): string {
  const host = window.location.hostname;
  const subdomain = host.split('.')[0];
  if (subdomain.includes('-')) {
    return last(subdomain.split('-')) as string;
  } else {
    return '';
  }
}

export function getWmcBranch(): string {
  return wmcbranchParam || '';
}

export function getAppserverHost() {
  return appserverParam || process.env.REACT_APP_APPSERVER as string;
}

export function getAppserverEndpoint(): string {
  const host = getAppserverHost();
  const scheme = host.startsWith('localhost') ? 'http' : 'https';
  return `${scheme}://${host}`;
}

export function getAuthEndpoint(): string {
  return getAppserverEndpoint();
}

export function getApiEndpoint(): string {
  return `${getAppserverEndpoint()}/${process.env.REACT_APP_GQL_PATH}`;
}

export function getAuthClientId(): string {
  return process.env.REACT_APP_AUTH_CLIENT_ID as string;
}

export function isAutomatedTest() {
  return ((window as any).Cypress || isUnitTest()) ? true : false;
}

export function isUnitTest() {
  return process.env.REACT_APP_TEST ? true : false;
}

export function isEmbedded() {
  const sendEmbeddedMessage = (window as any).sendEmbeddedMessage;
  return sendEmbeddedMessage !== undefined;
}

export function isDownloadStatusEnabled() {
  const downloadStatusEnabled = (window as any).downloadStatusEnabled
  return downloadStatusEnabled !== undefined;
}

export function isOpenWmcEnabled() {
  const openWmcEnabled = (window as any).openWmcEnabled
  return openWmcEnabled !== undefined;
}
