import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppContext, { getAppContextState } from 'AppContext';
import { Spinner, Header, Footer, Navigation, Unauthorized, Logout, PluginContainer, TimeSkewed } from 'core/components';
import { useDOMEvent, useTranslator } from 'core/hooks';
import { Router, Switch, Route, Redirect } from "react-router-dom";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import initializeI18N from './i18n';
import initializeLocalForage from './localForageSetup';
import { sendEmbeddedMessage, embeddedMessagePublisher, addEmbeddedMessageHandler, navigate, getPathParams } from 'core/utils';
import { JobManager } from 'interfaces';

export const defaultPlugin = '/jobmanager';

UIkit.use(Icons);

function App() {
  const t = useTranslator();
  const [context, setContext] = useState(getAppContextState());
  const { history, authorizer, store } = context;
  useDOMEvent('online'); // This hook will force a re-render when there's a network online event.

  context.toggleDeveloperMode = () => setContext({ ...context, developerMode: !context.developerMode });

  useEffect(() => {
    fetch('index.html').then(response => {
      const browserTime = new Date().getTime();
      const serverTime = Date.parse(response.headers.get('date') as string);
      const diff = Math.abs(browserTime - serverTime);
      if (diff > 10 * 60 * 1000) { //if time difference is greater than 10 min
        console.log('Browser Time:' + browserTime);
        console.log('Server Time:' + serverTime);
        navigate({ pathParams: ['timeSkewed'] });
      }      
    });

    sendEmbeddedMessage('initialize', { publisher: embeddedMessagePublisher });
    const unsub = addEmbeddedMessageHandler('redirectTo', (payload) => {
      const path = payload.path;
      if (path === 'newjob') {
        //Navigate to Home screen first, in case 'New job' page is already opened
        navigate({ pathParams: [getPathParams()[0]] });
        navigate({ pathParams: [getPathParams()[0], JobManager.createNewJobPath()] });
      } else if (path === 'home') {
        navigate({ pathParams: [getPathParams()[0]] });
      }
    });
    return unsub;
  }, [t]);

  return (
    <Router history={history}>
      <Provider store={store.reduxStore}>
        <AppContext.Provider value={context} >
          <div className="App uk-position-cover uk-flex">
            <Navigation />
            <div className="main-view uk-flex-1 uk-flex uk-flex-column">
              <Header />
              {authorizer.isUserAuthenticated(['use:cloud-connected']) ?
                <Switch>
                  <Redirect exact from="/" to={{ pathname: defaultPlugin, search: window.location.search }} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/timeSkewed" component={TimeSkewed} />
                  <Route path="/:pluginName/:pluginParam1?/:pluginParam2?" component={PluginContainer} />
                </Switch> :
                <Switch>
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/timeSkewed" component={TimeSkewed} />
                  <Unauthorized />
                </Switch>
              }
              <Footer />
            </div>
          </div>
        </AppContext.Provider>
      </Provider>
    </Router>
  );
}

App.run = async () => {
  const { authorizer } = getAppContextState();
  const root = document.getElementById('root');

  ReactDOM.render(<Spinner light={true} ratio={3} position="center" />, root);
  await authorizer.initialize();
  await initializeI18N();
  await initializeLocalForage();
  ReactDOM.render(<App />, root);
};

export default App;
