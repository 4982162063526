import { DocumentNode, GraphQLObjectType, GraphQLInterfaceType } from 'graphql';
import { normalize, Variables } from 'graphql-norm';
import dotProp from 'dot-prop';
import { without, union } from 'lodash';
import { buildClientSchema } from 'graphql/utilities';
import schemaJson from './schema.json';
import { getAppContextState } from 'AppContext';
import { Action, ActionWithPayload, State } from 'core/Store';

const schema = buildClientSchema(schemaJson as any);

export type StateOrStoreSection = State | string;
export type GraphQLObject = Record<string, any> & {
  id: string;
  __typename: string;
};
export type NormalizedGraphQLState = Record<string, Record<string, GraphQLObject>>;

// Return a normalized object map of the provided GraphQL query/variables/data set.
export function normalizeGraphQLData(query: DocumentNode, variables: Variables | undefined, data: Record<string, any>) {
  data = normalize(query, variables, data);

  // Don't flatten objects that don't have an id.
  for (let [key, obj] of Object.entries(data)) {
    if (!obj.id) {
      dotProp.set(data, key, obj);
      delete data[key];
    }
  }

  delete data['ROOT_QUERY'];

  // Add __owners.
  const addOwner = (objKey: string, ownerKey: string) => {
    const obj = data[objKey];
    if (obj) {
      addGraphQLObjectOwner(obj, ownerKey);
    }
  }

  for (let [key, obj] of Object.entries(data)) {
    for (let [field, value] of Object.entries(obj)) {
      if (field.startsWith('__')) {
        continue;
      }
      if (typeof value === 'string') {
        addOwner(value, key);
      } else if (Array.isArray(value) && typeof value[0] === 'string') {
        value.map((item: string) => addOwner(item, key));
      }
    }
  }


  // Create type-based maps.
  const objectMap: NormalizedGraphQLState = {};
  for (let [key, obj] of Object.entries(data)) {
    dotProp.set(objectMap, key.replace(':', '.'), obj);
  }

  // Reinsert __typename.
  for (let [type, objects] of Object.entries(objectMap)) {
    for (let obj of Object.values(objects)) {
      (obj as any).__typename = type;
    }
  }

  return objectMap;
}

// Add/update the GraphQL objects in the given state with the given normalized state.
export function updateGraphQLState(state: State, updates: NormalizedGraphQLState) {
  const owners = new Set<string>();

  const updateObject = (current: GraphQLObject, obj: GraphQLObject) => {
    const owners = union(current.__owners || [], obj.__owners || []);
    Object.assign(current, obj);
    current.__owners = owners;
  };

  for (let [type, objects] of Object.entries(updates)) {
    if (!state[type]) {
      state[type] = objects;
    } else {
      for (let [id, obj] of Object.entries(objects)) {
        const current = state[type][id];
        if (current) {
          updateObject(current, obj);
          buildOwnerSet(current, state, owners);
        } else {
          state[type][id] = obj;
        }
      }
    }
  }

  // Touch all owners as well.
  touchOwners(owners, state);
}

// Add/update the GraphQL objects in the given state with the given action.
export function updateGraphQLStateFromAction(state: State, action: Action) {
  updateGraphQLState(state, (action as ActionWithPayload).payload.normalizedData);
}

// Add an object reference to object's list in the given state.
export function addGraphQLObjectReferenceToList(state: State, owner: GraphQLObject, listFieldName: string, obj: GraphQLObject) {
  owner[listFieldName] = [ ...(owner[listFieldName] || []), getGraphQLObjectKey(obj) ];
  addGraphQLObjectOwner(obj, getGraphQLObjectKey(owner));
  touchOwners(buildOwnerSet(owner, state), state);
}

// Remove an object from the given state. Also remove all references to the object.
// If the object is the sole owner of sub-objects, remove those objects too.
export function removeGraphQLObjectFromState(state: State, obj: GraphQLObject) {
  const objectMap = state;
  const objKey = getGraphQLObjectKey(obj);

  // Delete object from state.
  const type = obj.__typename;
  const objectsOfType = { ...objectMap[type] };
  delete objectsOfType[obj.id];
  objectMap[type] = objectsOfType;

  // Remove as owner from sub-objects (delete solely-owned sub-objects as well).
  const removeOwner = (objKey: string, ownerKey: string) => {
    const obj = toGraphQLObject(objKey, objectMap);
    if (obj && obj.__owners) {
      obj.__owners = without(obj.__owners, ownerKey);
      if (obj.__owners.length === 0) {
        removeGraphQLObjectFromState(objectMap, obj);
      }
    }
  }

  for (let value of Object.values(obj)) {
    if (typeof value === 'string') {
      removeOwner(value, objKey);
    } else if (Array.isArray(value) && typeof value[0] === 'string') {
      value.map((item: string) => removeOwner(item, objKey));
    }
  }

  // Remove object's reference from owners.
  if (obj.__owners) {
    obj.__owners.forEach((ownerKey: string) => {
      const owner = toGraphQLObject(ownerKey, objectMap);
      if (owner) {
        removeGraphQLObjectReference(owner, objKey);
      }
    });
  }
}

// Denormalize all objects in the given data by recursively replacing all references to other objects
// with a copy of the objects themselves.
export function denormalizeGraphQLData<T=State>(data: any): T {
  const objectMap = getGraphQLObjectMap();
  const tryDenormalize = (key: string) => {
    const obj = findGraphQLObject(key, objectMap);
    return obj ? denormalizeGraphQLObject(obj, objectMap) : key;
  };

  if (typeof data === 'object' && data.__typename) {
    data = denormalizeGraphQLObject(data, objectMap);
  } else if (Array.isArray(data) && typeof data[0] === 'string') {
    data = data.map(tryDenormalize);
  } else if (typeof data === 'string') {
    data = tryDenormalize(data as string);
  }

  return data as T;
}

// Denormalize an object by recursively replacing all references to other objects with a copy of the objects themselves.
export function denormalizeGraphQLObject(obj: GraphQLObject, objectMap?: NormalizedGraphQLState): GraphQLObject {
  if (!objectMap) {
    objectMap = getGraphQLObjectMap();
  }

  function isString(value: any) {
    if (Array.isArray(value)) {
      value = value[0];
    }
    return typeof value === 'string';
  }

  const objType = schema.getType(obj.__typename);
  if (!objType) {
    return obj;
  }

  const denormalizedObj = { ...obj };
  const toDenormalizedObject = (key: string) => toGraphQLObject(key, objectMap as NormalizedGraphQLState, true);

  for (let [key, value] of Object.entries(obj)) {
    let fieldType = (objType as any).getFields()[key]?.type;
    while (fieldType?.ofType) {
      fieldType = fieldType.ofType;
    }
    if ((fieldType instanceof GraphQLObjectType || fieldType instanceof GraphQLInterfaceType) && isString(value)) {
      denormalizedObj[key] = Array.isArray(value) ? value.map(toDenormalizedObject): toDenormalizedObject(value);
    }
  }

  return denormalizedObj;
}

export function addGraphQLObject(list: string[], object: GraphQLObject) {
  const key = `${object.__typename}:${object.id}`;
  return [...list, key];
}

export function removeGraphQLObject(list: string[], object: GraphQLObject) {
  const key = `${object.__typename}:${object.id}`;
  return without(list, key);
}

function getGraphQLObjectKey(obj: GraphQLObject) {
  return `${obj.__typename}:${obj.id}`;
}

function addGraphQLObjectOwner(obj: GraphQLObject, ownerKey: string) {
  obj.__owners = union(obj.__owners || [], [ownerKey]);
};

function removeGraphQLObjectReference(owner: GraphQLObject, objKey: string) {
  for (let [field, value] of Object.entries(owner)) {
    if (value === objKey) {
      owner[field] = null;
    } else if (Array.isArray(value) && value.includes(objKey)) {
      owner[field] = without(owner[field], objKey);
    }
  }
}

function buildOwnerSet(obj: GraphQLObject, objectMap: NormalizedGraphQLState, owners?: Set<string>) {
  if (!owners) {
    owners = new Set<string>();
  }

  for (let ownerKey of obj.__owners || []) {
    if (!owners.has(ownerKey)) {
      const owner = toGraphQLObject(ownerKey, objectMap);
      if (owner) {
        owners.add(ownerKey);
        buildOwnerSet(owner, objectMap, owners);
      }
    }
  }

  return owners;
};

function touchOwners(owners: Set<string>, objectMap: NormalizedGraphQLState) {
  owners.forEach((ownerKey: string) => {
    const owner = toGraphQLObject(ownerKey, objectMap);
    for (let [field, value] of Object.entries(owner)) {
      owner[field] = value;
    }
    objectMap[owner.__typename][owner.id] = owner;
  });
}

function toGraphQLObject(key: string, objectMap: NormalizedGraphQLState, denormalize?: boolean) {
  let obj = findGraphQLObject(key, objectMap);
  if (obj && denormalize) {
    obj = denormalizeGraphQLObject(obj, objectMap);
  }
  return obj;
}

function findGraphQLObject(key: string, objectMap: NormalizedGraphQLState): GraphQLObject {
  return dotProp.get<Record<string, any>>(objectMap, key.replace(':', '.')) as GraphQLObject;
}

function getGraphQLObjectMap(): NormalizedGraphQLState {
  const { store } = getAppContextState();
  return store.getState('graphql') as NormalizedGraphQLState;
}
