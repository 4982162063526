import React, { ErrorInfo as ReactErrorInfo } from 'react';
import Modal from './Modal';
import { delay } from 'lodash';
import { getAppContextState } from 'AppContext';
import i18n from 'i18next';
import { ErrorInfo } from 'core/components';
import { GraphQLNetworkError } from 'core/graphql';
import { showNetworkErrorNotification } from 'core/utils';

class ErrorBoundary extends React.Component<{}, { error?: Error }> {
  constructor(props: any) {
    super(props);
    this.state = {};
    window.onunhandledrejection = (event: PromiseRejectionEvent) => this.onUnhandledError(event.reason);
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, _: ReactErrorInfo) {
    if (error instanceof GraphQLNetworkError) {
      showNetworkErrorNotification();
      this.setState({ error: undefined });
    } else {
      const context = getAppContextState();

      context.error = error;

      Modal.hide();
      delay(() =>
        Modal.show({
          title: i18n.t('core.label.unexpectedError'),
          body: <ErrorMessage error={error} />,
          onClose: () => {
            this.setState({ error: undefined });
            context.error = undefined;
          },
        }), 500
      );
    }
  }

  render() {
    return this.state.error ? null : this.props.children;
  }

  onUnhandledError(error: any) {
    this.setState(ErrorBoundary.getDerivedStateFromError(error));
    this.componentDidCatch(error, {} as ReactErrorInfo);
  }
}

function ErrorMessage({ error }: { error : Error }) {
  return (
    <div>
      <p>{error.message}</p>
      <ErrorInfo error={error} />
    </div>
  );
}

export default ErrorBoundary;
