import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const fallbackLng = ['en']; 
const availableLanguages = ['en'];

type InitializeI18NOptions = {
  backend?: any,
  initOptions?: Record<string, any>,
};

const defaultInitOptions = {
  fallbackLng,
  whitelist: availableLanguages,
  interpolation: {
    escapeValue: false, // not needed for react.
  },
  backend: {
    loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
  },
  react: {
    transSupportBasicHtmlNodes: true,
  }
};

async function initializeI18N({ backend, initOptions }: InitializeI18NOptions = {}) {
  backend = backend || Backend;
  initOptions = { ...defaultInitOptions, ...(initOptions || {}) };
  await i18n
    .use(backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(initOptions);
}

export default initializeI18N;
