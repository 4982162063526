import jwtDecode from 'jwt-decode';
import randomBytes from 'randombytes';
import createHash from 'create-hash';
import base64url from 'base64url';
import { History } from 'history';
import moment from 'moment';
import { getAppserverHost, getSearchParams, isAutomatedTest } from 'core/utils';

const AUTH_STORAGE_KEY = 'Authorization';
const VERIFIER_STORAGE_KEY = 'Verifier';

export type AuthorizerOptions = {
  authEndpoint: string,
  clientId: string,
  history?: History,
  autoLogin?: boolean,
};

export type AuthData = {
  authEndpoint: string,
  accessToken: string,
  idToken: string,
  refreshToken: string,
  accessTokenExp: number,
  refreshTokenExp: number,
  email: string,
  givenName: string,
  familyName: string,
  permissions: string[],
};

const dummyIdToken = {
  email: 'testuser@cd-adapco.com',
  given_name: 'test',
  family_name: 'user',
};

export class Authorizer {
  protected options: AuthorizerOptions;
  protected authData: AuthData | null;

  constructor(options: AuthorizerOptions) {
    this.options = options;
    this.authData = this.getStoredAuthData();
  }

  async initialize() {
    const isInIframe = window.parent !== window.self;
    const searchParams = getSearchParams();

    if (searchParams.has('code') || searchParams.has('error')) {
      await this.handleAuthCallback(searchParams);
    } else if (!this.isUserAuthenticated()) {
      if (isInIframe) {
        this.login();
      } else {
        await this.tryTokenRefresh() || await this.trySilentAuth();
        if (!this.isUserAuthenticated() && this.options.autoLogin) {
          this.login();
        }
      }
    }

    if (isInIframe) {
      await this.waitForRedirect();
    } else if (this.isUserAuthenticated()) {
      this.scheduleTokenRefresh();
    }
  }

  async login() {
    const uri = this.options.authEndpoint + '/auth';
    const state = this.createState();
    const verifier = this.createAndStoreCodeVerifier();
    const codeChallenge = this.createCodeChallenge(verifier);
    const redirectUri = this.getRedirectUriFromState(state);

    const params = new URLSearchParams([
      ['client_id', this.options.clientId],
      ['scope', 'openid profile sam_account read:apikey'],
      ['response_type', 'code'],
      ['redirect_uri', redirectUri],
      ['code_challenge', codeChallenge],
      ['code_challenge_method', 'S256'],
      ['state', JSON.stringify(state)],
    ]);

    const url = `${uri}?${params.toString()}`;
    window.location.href = url;
    await this.waitForRedirect();
  }

  async logout(state: Record<string, any> = {}) {
    const idToken = this.authData ? this.authData.idToken : '';
    state = { ...this.createState(), ...state };
    const redirectUri = this.getRedirectUriFromState(state);
    this.deleteStoredAuthData();
    const params = new URLSearchParams([
      ['id_token_hint', idToken],
      ['post_logout_redirect_uri', redirectUri],
      ['state', JSON.stringify(state)],
    ]);
    const url = `${this.options.authEndpoint}/logout?${params.toString()}`;
    window.location.href = url;
    await this.waitForRedirect();
  }

  isUserAuthenticated(permissions: string[]=[]) {
    if (this.hasValidAuthData()) {
      const missingPermissions = permissions.filter((permission: string) => !this.hasPermission(permission));
      return missingPermissions.length === 0;
    }
    return false;
  }

  hasPermission(permission: string) {
    return this.hasValidAuthData() && this.getAuthData().permissions?.includes(permission);
  }

  getAuthData(): AuthData {
    if (this.authData) {
      return this.authData;
    } else {
      throw new Error('AuthData is undefined.');
    }
  }

  authenticatedFetch = async (resource: string, init: Record<string, any>) => {
    if (this.isUserAuthenticated()) {
      let headers = init.headers || {};
      headers = { ...headers, ...this.getAuthHeaders() };
      init = { ...init, headers };
    }
    return await fetch(resource, init);
  };

  getAuthHeaders() {
    const accessToken = this.authData && this.authData.accessToken;
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  private hasValidAuthData() {
    if (this.authData) {
      const expired = this.getSecondsToExpiration(this.authData.accessTokenExp) <= 60;
      return (
        !expired &&
        this.authData.authEndpoint === this.options.authEndpoint &&
        this.authData.permissions !== undefined
      );
    }
    return false;
  }

  private async waitForRedirect() {
    await new Promise(resolve => setTimeout(resolve, 5000));
  }

  private scheduleTokenRefresh() {
    if (isAutomatedTest()) {
      return;
    }

    const accessTokenExp = (this.authData as AuthData).accessTokenExp;
    const timeoutSecs = Math.max(this.getSecondsToExpiration(accessTokenExp) - 300, 0);
    setTimeout(async () => {
      if (!await this.tryTokenRefresh() && !await this.trySilentAuth()) {
        this.login();
      } else {
        this.scheduleTokenRefresh();
      }
    }, timeoutSecs * 1000);
  }

  private async tryTokenRefresh(): Promise<boolean> {
    if (this.authData?.refreshTokenExp && this.getSecondsToExpiration(this.authData.refreshTokenExp) > 10) {
      try {
        const tokens = await this.fetchTokensWithRefreshToken(this.authData.refreshToken);
        this.storeAuthData(tokens);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  /*
   * It's possible for the user's access token to expire, but the user still has
   * a valid login session as far as SAM is concerned. That session info is stored
   * in cookies by SAM. This function silently tries to refresh the access token by
   * loading this app (current url) in an iframe and trying to log in. If SAM still
   * has a valid session, this app (running in the iframe) will refresh the auth data
   * in localstorage.
   */
  private async trySilentAuth(): Promise<boolean> {
    if (isAutomatedTest()) {
      return false;
    }

    const iframe = window.document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = window.location.href;

    // We need to give the iframe a chance to load its content and refresh the auth data in localstorage,
    // so we'll monitor the iframe as it loads.
    const iframeMonitor = new Promise((resolve: any) => {
      // The longest we will wait for the iframe to refresh auth data is 3 seconds after the it loads.
      let timeoutId = setTimeout(resolve, 3000);

      iframe.onload = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // When there's content in the iframe, we will try checking for refreshed auth data sooner.
        let iframeHasContent = false;
        try {
          if (iframe.contentDocument) {
            iframeHasContent = true;
          }
        } catch {
          // CLD-344: We might get an error when trying to access iframe.contentDocument
          // in some cases. When that happens, we'll simply wait the 3 seconds.
        }

        if (iframeHasContent) {
          const interval = 200;
          let wait = interval * 100;
          const intervalId = setInterval(() => {
            if (this.getStoredAuthData() || wait <= 0) {
              clearInterval(intervalId);
              resolve();
            }
            wait -= interval;
          }, interval);
        } else {
          timeoutId = setTimeout(resolve, 3000);
        }
      };
    });

    // Delete the expired auth data, load the iframe, and give the iframe a chance
    // to refresh the auth data if possible.
    this.deleteStoredAuthData();
    window.document.body.appendChild(iframe);
    await iframeMonitor;

    // Remove the iframe, load any newly-stored auth data, and return true if auth data exists.
    iframe.remove();
    this.authData = this.getStoredAuthData();
    return this.authData ? true : false;
  }

  private async handleAuthCallback(searchParams: URLSearchParams) {
    let redirectUri = '/';

    try {
      if (searchParams.has('code')) {
        const state = this.getStateFromSearchParams(searchParams);
        const tokens = await this.fetchTokensWithCode(searchParams.get('code') as string, state);
        this.storeAuthData(tokens);

        if (state.from) {
          const terms = state.from.toLowerCase().split('?')[0].split('/');
          if (terms[terms.length -1] !== 'logout') redirectUri = state.from;
        }        
      } else {
        throw new Error(searchParams.get('error_description') as string);
      }
    } catch (e) {
      window.alert(`Authentication error: ${e.message}`);      
    }    
    
    this.redirect(redirectUri);
  }

  private async redirect(uri: string) {
    if (this.options.history && uri === '/') {
      this.options.history.replace(uri);
    } else {
      window.location.href = `${window.location.origin}${uri}`;
      await this.waitForRedirect();
    }
  }

  private async fetchTokensWithCode(code: string, state: Record<string, any>) {
    const verifier = window.sessionStorage.getItem(VERIFIER_STORAGE_KEY);
    const redirectUri = this.getRedirectUriFromState(state);

    const data = {
      grant_type: 'authorization_code',
      client_id: this.options.clientId,
      code_verifier: verifier,
      code: code,
      redirect_uri: redirectUri,
    };

    window.sessionStorage.removeItem(VERIFIER_STORAGE_KEY);
    return await this.fetchTokens(data);
  }

  private async fetchTokensWithRefreshToken(refreshToken: string) {
    const data = {
      grant_type: 'refresh_token',
      client_id: this.options.clientId,
      refresh_token: refreshToken,
    };
    return await this.fetchTokens(data);
  }

  private async fetchTokens(data: Record<string, any>) {
    const url = this.options.authEndpoint + '/token';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: this.encodeFormData(data),
    });
    if (response.status !== 200) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    const tokens = await response.json();
    return tokens;
  }

  private createState() {
    let state = this.getStateFromSearchParams();
    if (!state.from) {
      const from = window.location.pathname + window.location.search;
      state = {
        origin: window.location.origin,
        from,
      };
    }
    if (!state.appserver) {
      state.appserver = getAppserverHost();
    }
    return state;
  }

  private getStateFromSearchParams(searchParams?: URLSearchParams) {
    if (!searchParams) {
      searchParams = getSearchParams();
    }
    return JSON.parse(searchParams.get('state') || '{}');
  }

  private getRedirectUriFromState(state: Record<string, any>) {
    return state.origin + '/';
  }

  private storeAuthData(tokens: Record<string, string>) {
    const accessToken = jwtDecode(tokens.access_token) as Record<string, any>;
    const idToken = (tokens.id_token ? jwtDecode(tokens.id_token) : dummyIdToken) as Record<string, any>;
    const refreshToken = (tokens.refresh_token ? jwtDecode(tokens.refresh_token) : {}) as Record<string, any>;

    this.authData = {
      authEndpoint: this.options.authEndpoint,
      accessToken: tokens.access_token,
      idToken: tokens.id_token,
      refreshToken: tokens.refresh_token,
      accessTokenExp: accessToken.exp as number,
      refreshTokenExp: refreshToken.exp as number,
      email: idToken.email as string,
      givenName: idToken.given_name as string,
      familyName: idToken.family_name as string,
      permissions: accessToken.permissions || [],
    };

    window.localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this.authData));
  }

  private getSecondsToExpiration(exp: number) {
    return exp - moment().unix();
  }

  private getStoredAuthData(): AuthData {
    const data = window.localStorage.getItem(AUTH_STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  }

  private deleteStoredAuthData() {
    this.authData = null;
    window.localStorage.removeItem(AUTH_STORAGE_KEY);
  }

  private createAndStoreCodeVerifier() {
    const verifier = base64url(randomBytes(32));
    window.sessionStorage.setItem(VERIFIER_STORAGE_KEY, verifier)
    return verifier;
  }

  private createCodeChallenge(verifier: string) {
    const hash = createHash('sha256').update(verifier).digest();
    return base64url(hash);
  }

  private encodeFormData(data: Record<string, any>) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }
}

export default Authorizer;