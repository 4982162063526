/**
 * Returns filename from path.
 * For  e.g. if path is '/path/to/file/abc.txt' returns 'abc.txt';
 * @param path File path
 */
export function getBaseName(path: string) {
    if (path) {
        return path.replace(/^.*[\\/]/, '');
    }
    return '';
}