import { MutationOptions, QueryOptions } from 'apollo-client';
import { Action, ActionWithPayload } from 'core';
import { getAppContextState } from 'AppContext';
import { normalizeGraphQLData } from 'core/graphql';
import { withAsyncLock, isAutomatedTest, hideNetworkErrorNotification } from 'core/utils';
import i18n from 'i18next';

const ASYNC_LOCK_KEY = 'graphql-request-lock';

export type GraphQLRequestOptions = {
  transformer?: (data: any) => any,
};

function checkUnauthenticated(errors: any[]) {
  const { authorizer } = getAppContextState();
  errors.forEach((error: any) => {
    if (error.statusCode === 401 || error.statusCode === 403 ||
        error.extensions?.code === 'APP_AuthorizationError' ||
        error.extensions?.code === 'FORBIDDEN') {
      authorizer.login();
    }
  });
}

export class GraphQLNetworkError extends Error {
  constructor(public action: ActionWithPayload) {
    super(i18n.t('core.message.networkError'));
  }
}

export class GraphQLApiError extends Error {
  constructor(public action: ActionWithPayload) {
    super(i18n.t('core.message.apiError'));
  }
}

export async function dispatchGraphQLRequest(
  type: string,
  request: Record<string, any>,
  { transformer }: GraphQLRequestOptions = {}): Promise<Action> {

  const { graphQLClient, store } = getAppContextState();
  let result: any;

  async function dispatch() {
    const operation = request.mutation ? 'mutation' : 'query';
    const variables = request.variables;
    const body = request[operation].loc.source.body;
    const timingLabel = `${type} request timing`;

    const pendingAction = { type: `${type}/pending`, payload: { [operation]: body, variables } };
    store.dispatch(pendingAction);

    if (isAutomatedTest()) {
      console.debug(JSON.stringify(pendingAction, null, 2));
      console.time(timingLabel);
    }

    try {
      result = request.mutation ?
        await graphQLClient.mutate(request as MutationOptions) :
        await graphQLClient.query(request as QueryOptions);
    } catch (e) {
      const errors = e.networkError?.result?.errors || [{ message: e.message, statusCode: e.networkError?.statusCode }];
      result = { errors };
    }

    if (isAutomatedTest()) {
      console.timeEnd(timingLabel);
      console.debug(JSON.stringify(result, null, 2));
    }

    if (result.errors) {
      const errorAction = { type: `${type}/error`, payload: result }
      store.dispatch(errorAction);
      checkUnauthenticated(result.errors);
      if (result.errors[0].message.includes('Network')) {
        throw new GraphQLNetworkError(errorAction);
      } else {
        // prevent output log from being stopped if the Resource Manager is not responding for a short while
        if (type.includes('jobManager/queryComputeTaskLog')) {
          console.log(`cant query task log ${result}`);
        } else {
          hideNetworkErrorNotification();
          throw new GraphQLApiError(errorAction);
        }
      }
    } else {
      const normalizedData = normalizeGraphQLData(request[operation], variables, result.data);
      const data = transformer ? await Promise.resolve(transformer(result.data)) : result.data;
      const payload = {
        [operation]: body,
        variables,
        data,
        normalizedData,
      };
      hideNetworkErrorNotification();
      return store.dispatch({ type, payload });
    }
  }

  const action = await withAsyncLock(ASYNC_LOCK_KEY, dispatch);
  return action;
}
