const loadedPlugins: Record<string, any> = {};

export async function loadComponentPlugin(pluginName: string) {
  return await loadPlugin(`${pluginName}/components`);
}

export async function loadModelPlugin(pluginName: string) {
  return await loadPlugin(`${pluginName}/models`);
}

async function loadPlugin(pluginPath: string) {
  let plugin = loadedPlugins[pluginPath];
  if (plugin) {
    return plugin;
  }

  const mod = await import(`plugins/${pluginPath}`);

  if (mod.modelPlugins) {
    for (let i = 0; i < mod.modelPlugins.length; i++) {
      await loadModelPlugin(mod.modelPlugins[i]);
    }
  }
  if (mod.componentPlugins) {
    for (let i = 0; i < mod.componentPlugins.length; i++) {
      await loadComponentPlugin(mod.componentPlugins[i]);
    }
  }

  if (mod.initialize) {
    plugin = await Promise.resolve(mod.initialize()) || mod;
  } else {
    plugin = mod;
  }

  loadedPlugins[pluginPath] = plugin;
  return plugin;
}
