import localforage from 'localforage';
import * as memoryDriver from 'localforage-driver-memory';

async function initializeLocalForage() {
  localforage.defineDriver(memoryDriver);
  localforage.config({
    name: process.env.REACT_APP_NAME,
    driver: [localforage.INDEXEDDB, memoryDriver._driver],
  });
  await localforage.clear();
}

export default initializeLocalForage;
