import { useEffect, useState, useCallback } from 'react';

export default function useDOMEvent(eventName: string,
  { listener, element }: { listener?: EventListener, element: HTMLElement | Window | Document } = { element: window }) {
  const [event, setEvent] = useState<Event | undefined>();

  const onEvent = useCallback((event: Event) => {
    if (listener) {
      listener(event);
    } else {
      setEvent(event);
    }
  }, [listener]);

  useEffect(() => {
    element.addEventListener(eventName, onEvent);
    return () => {
      element.removeEventListener(eventName, onEvent);
    };
  }, [eventName, element, onEvent]);

  return event;
};
