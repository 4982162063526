import { createStore, compose, combineReducers, Store as ReduxStore } from 'redux';
import { FluxStandardAction, FluxStandardActionWithPayload } from 'flux-standard-action';
import dotProp from 'dot-prop-immutable';
import { EventEmitter } from 'fbemitter';

export type State = Record<string, any>;
export type Action<Type extends string=string, Payload=State, Meta=State> = FluxStandardAction<Type, Payload, Meta>;
export type ActionWithPayload<Type extends string=string, Payload=State, Meta=State> = FluxStandardActionWithPayload<Type, Payload, Meta>;
export type Reducer = (state: State | undefined, action: Action) => State;
export type StateChangeListener<StateType> = (newState: StateType) => void;
export type ActionListener = (action: Action, state: State) => void;
export type ActionDispatcher = (input?: any) => Action | Promise<Action>;

export type SelectorFunction<StateType=any, SelectedStateType=any> = (state: StateType) => SelectedStateType;
export type Selector<StateType=any, SelectedStateType=any> = SelectorFunction<StateType, SelectedStateType> | string;
export type EqualityFunction = (left: any, right: any) => boolean;

const storeEnhancer = compose(
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export class Store {
  public _reduxStore: ReduxStore;
  private sectionReducers: Record<string, Reducer> = {};
  private combinedSectionReducer: Reducer | undefined;
  private prevState: State = {};
  private changeEmitter = new EventEmitter();
  private lastAction: Action | undefined;

  constructor() {
    this._reduxStore = this.createReduxStore();
  }

  get reduxStore() {
    return this._reduxStore;
  }

  public createStoreSection(section: string, reducer: Reducer) {
    this.sectionReducers[section] = reducer;
    const reducers = { ...this.sectionReducers };
    reducers.graphql = this.graphqlReducer;
    this.combinedSectionReducer = combineReducers(reducers);
    this.dispatch({ type: '@@CREATE_STORE_SECTION', payload: { section }});
  }

  public getState<StateType=State>(path: string = ''): StateType {
    const state = this.reduxStore.getState();
    return path ? dotProp.get(state, path) : state;
  }

  public dispatch = (action: Action): Action | Promise<Action> => {
    this.prevState = this.reduxStore.getState();
    return this.reduxStore.dispatch(action as Action);
  };

  public subscribe<StateType=State>(listener: StateChangeListener<StateType>, path: string = '') {
    const token = this.changeEmitter.addListener('state', (prevState: State, newState: State) => {
      prevState = dotProp.get(prevState, path);
      newState = dotProp.get(newState, path);
      if (!Object.is(prevState, newState)) {
        listener(newState as StateType);
      }
    });
    return () => token.remove();
  }

  public subscribeToActions(listener: ActionListener) {
    const token = this.changeEmitter.addListener('action', listener);
    return () => token.remove();
  }

  // This is used for unit testing purposes.
  public reset() {
    this._reduxStore = this.createReduxStore();
  }

  protected createReduxStore() {
    const reduxStore = createStore<State, Action, any, any>(this.rootReducer, storeEnhancer);
    reduxStore.subscribe(this.onStateChange);
    return reduxStore;
  }

  protected rootReducer = (state: State = {}, action: Action): State => {
    if (action.type.endsWith('/error')) {
      console.error(JSON.stringify(action));
    }

    const updateState = (section: string, currentSectionState: State, newSectionState: State) => {
      newSectionState = { ...newSectionState };
      const { graphql } = newSectionState;
      delete newSectionState.graphql;
      if (!Object.is(currentSectionState.graphql, graphql)) {
        state = { ...state, graphql };
      }
      state = { ...state, [section]: newSectionState };
    };

    this.lastAction = action;

    if (this.combinedSectionReducer) {
      if (action.type.startsWith('@@')) {
        state = this.combinedSectionReducer(state, action);
      } else {
        for (let [section, reducer] of Object.entries(this.sectionReducers)) {
          const currentSectionState = { ...state[section], graphql: state.graphql };
          const newSectionState = reducer(currentSectionState, action);
          if (!Object.is(currentSectionState, newSectionState)) {
            updateState(section, currentSectionState, newSectionState);
          }
        }
      }
    }

    return state;
  };

  protected graphqlReducer = (state: State = {}, _: Action): State => {
    return state;
  };

  protected onStateChange = () => {
    const newState = this.reduxStore.getState();
    this.changeEmitter.emit('action', this.lastAction, newState);
    this.changeEmitter.emit('state', this.prevState, newState);
  }
}

export default Store;
