export class Registrar {
  private actionsRegistry: Record<string, any> = {};
  private helpersRegistry: Record<string, any> = {};
  private componentsRegistry: Record<string, any> = {};
  private modelTypes: Record<string, any> = {};

  public registerActions(key: string, actions: Record<string, any>) {
    this.actionsRegistry[key] = actions;
  }

  public registerHelpers(key: string, helpers: Record<string, any>) {
    this.helpersRegistry[key] = helpers;
  }

  public registerComponents(key: string, components: Record<string, any>) {
    this.componentsRegistry[key] = components;
  }

  public registerStoreModelType(typename: string, modelType: any) {
    this.modelTypes[typename] = modelType;
  }

  public getActions<T=any>(key: string): T {
    return this.actionsRegistry[key] as T;
  }

  public getHelpers<T=any>(key: string): T {
    return this.helpersRegistry[key] as T;
  }

  public getComponents<T=any>(key: string): T {
    return this.componentsRegistry[key] as T;
  }

  public toModel<ModelType=any>(obj: Record<string, any>): ModelType | undefined {
    if (Array.isArray(obj)) {
      const models = (obj as any[]).map((item: any) => this.toModel(item)) as any;
      return models as ModelType;
    } else {
      const modelType = obj && this.modelTypes[obj.__typename];
      return modelType ? new modelType(obj) as ModelType : undefined;
    }
  }
}
