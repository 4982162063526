import React, { FunctionComponent, useRef, DragEvent } from 'react';
import { range } from 'lodash';
import { default as fileTypeAccept } from 'attr-accept';
import { Modal } from 'core/components';
import { useTranslator } from 'core/hooks';

type FileSelectorProps = {
  multiple?: boolean,
  label?: string,
  onSelect: (files: File[]) => void,
  dropZone?: boolean,
  accept?: string,
};

const FileSelector: FunctionComponent<FileSelectorProps> = ({ multiple, label, onSelect, dropZone, accept, children }) => {
  multiple = multiple ? true : false;
  label = label || (multiple ? 'Select Files' : 'Select File');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputStyle = {
    width: '0.1px',
    height: '0.1px',
    zIndex: -1,
  };
  const t = useTranslator();

  function toFilesArray(files: FileList) {
    return files ? range(files.length).map((i: number) => files[i]) : [];
  }

  function onFileSelect(ev: any) {
    selectValidFiles(toFilesArray(ev.target.files));
  }

  function onDragOver(event: DragEvent<HTMLDivElement>) {
    containerRef?.current?.classList.add('uk-dragover');
    if (event.dataTransfer?.files.length > 0) {
      event.dataTransfer.dropEffect = 'copy';
    }
    event.preventDefault();
  }

  function onDragLeave(event: DragEvent<HTMLDivElement>) {
    containerRef?.current?.classList.remove('uk-dragover');
    event.preventDefault();
  }

  function onDrop(event: DragEvent<HTMLDivElement>) {
    const files = toFilesArray(event.dataTransfer?.files);
    selectValidFiles(files);
    containerRef?.current?.classList.remove('uk-dragover');
    event.preventDefault();
  }

  function selectValidFiles(files: File[]) {
    const rejected = accept ? files.find((file: File) => !fileTypeAccept(file, accept)) : false;
    if (files.length > 0 && !rejected) {
      onSelect(files);
    } else if (rejected) {
      // Show error if files are not matching accepted type (for sim file and macro files only)
      Modal.show({
        title: t('core.label.invalidFile'),
        body: t('core.message.invalidFileMessage', {file: rejected.name, type: accept}),
      });
    }
  }

  const dragAndDropProps = dropZone ? { onDragOver, onDragLeave, onDrop } : {};

  return (
    <div
      ref={containerRef}
      className="file-selector"
      data-uk-form-custom="target: dummy"
      {...dragAndDropProps}
      style={{ minWidth: '100%' }}>
      <label className="uk-button uk-button-link uk-button-small uk-text-left" style={{ minWidth: '100%' }}>
        <input
          type="file"
          multiple={multiple}
          value={undefined}
          title=" "
          accept={accept || ""}
          onChange={onFileSelect} style={inputStyle}/>
          {children || label}
        </label>
    </div>
  );
}

export default FileSelector;
