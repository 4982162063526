import React, { useState, useEffect } from 'react';
import { useAppContext, useTranslator } from 'core/hooks';
import { ReactComponent as UserIcon } from 'afx/image/cmdUser24.svg';
import { isAppExitBlocked, forceLogout } from 'core/utils';
import { ReactComponent as SignOutIcon } from 'afx/image/cmdSignout16.svg';
import { ReactComponent as PinIcon } from 'afx/image/uiPin12.svg';

import Modal from './Modal';

function Subnavigation({ onHide, mobile }: any) {
  const [pinned, setPinned] = useState<boolean>(false);

  useEffect(() => {
    function isClickInSubnav(element: HTMLElement): boolean {
      if (element.classList.contains('subnavigation')) {
        return true;
      }
      return element.parentElement ? isClickInSubnav(element.parentElement) : false;
    }

    function onDocumentClick(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (!isClickInSubnav(target)) {
        onHide();
      }
    }

    if (!pinned) {
      window.document.addEventListener('click', onDocumentClick as any);
      return () => window.document.removeEventListener('click', onDocumentClick as any);
    }
  }, [pinned, onHide]);

  function onTogglePinned() {
    setPinned(!pinned);
  }

  if (mobile && pinned) {
    setPinned(false);
  }

  const style = mobile ? { top: 45 } : {};
  return (
    <div className={`subnavigation uk-light uk-padding-small ${!pinned ? 'unpinned' : ''}`} style={style}>
      {!mobile && <Pinner onTogglePinned={(onTogglePinned)} />}
      <div className="uk-height-1-1 uk-flex uk-flex-column uk-flex-right uk-flex-middle">
        <User />
      </div>
    </div>
  );
}

function Pinner({ onTogglePinned }: any) {
  return (
    <div>
      <button className="pin uk-button uk-button-text uk-float-right"><PinIcon width={12} height={12} onClick={onTogglePinned}/></button>
    </div>
  );
}

function User() {
    const { authorizer, history } = useAppContext();
  const { givenName, familyName, email } = authorizer.getAuthData();
  const t = useTranslator();
  const style = {
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    background: '#fff',
  };

  function onLogout() {
    if (isAppExitBlocked()) {
      Modal.show({
        title: t('core.label.logout'),
        body: t('core.message.confirmLogoutWithChanges'),
        action: {
          label: t('core.label.logout'),
          cancelable: true,
          onClick: forceLogout,
        },
      });
    } else {
      history.replace('/logout', { from: window.location.pathname + window.location.search });
    }
  }

  return (
    <div className="uk-flex  uk-flex-column uk-flex-middle uk-text-center uk-flex-stretch uk-text-nowrap">
      <div className="uk-preserve-color uk-position-relative" style={style}>
        <div className="uk-position-center">
          <UserIcon width={52} height={52} />
        </div>
      </div>
      <p className="uk-margin-small-top">{`${givenName} ${familyName}`}</p>
      <p className="uk-margin-small-top">{email}</p>
      <div className="uk-margin-top" style={{ alignSelf: 'flex-start' }}>
        <button type="button" className="uk-button uk-button-text" onClick={onLogout} data-uk-tooltip={t('core.label.logout')}>
          <SignOutIcon width={24} height={24}/>
          <span style={{ marginLeft: '5px' }}>{t('core.label.logout')}</span>
        </button>
      </div>
    </div>
  );
}

export default Subnavigation;
