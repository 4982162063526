import React, { useEffect } from 'react';
import { Modal } from 'core/components';
import { useAppContext, useTranslator } from 'core/hooks';

function Unauthorized() {
  const { authorizer } = useAppContext();
  const t = useTranslator();

  useEffect(() => {
    if (authorizer.isUserAuthenticated()) {
      Modal.show({
        title: t('core.label.notPermitted'),
        body: t('core.message.notPermitted'),
      });
    }
  }, [authorizer, t]);

  function onLogin() {
    authorizer.login();
  }

  if (!authorizer.isUserAuthenticated()) {
    return (
      <button type="button" className="uk-button uk-button-secondary uk-position-center" onClick={onLogin}>
        {t('core.label.login')}
      </button>
    )
    } else {
      return null;
    }
}

export default Unauthorized;
