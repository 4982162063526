import React, { Suspense, useMemo } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { Spinner, ErrorBoundary } from 'core/components';
import { getSearchParams, getPathParams, loadComponentPlugin } from 'core/utils';
import pluginRegistry from 'pluginRegistry';

export type PluginComponentProps = {
  pathParams: string[];
  searchParams: URLSearchParams;
};

type MatchParams = {
  pluginName: string,
};

export function PluginContainer({ match }: RouteComponentProps<MatchParams>) {
  const pluginName = match.params.pluginName;
  const plugin = pluginRegistry.find(p => p.name === pluginName);
  const PluginComponent = useMemo(() => {
    if (plugin) {
      return React.lazy(async () => {
        return await loadComponentPlugin(plugin.name);
      });
    } else {
      return null;
    }
  }, [plugin]);

  return (
    <div className="content uk-overflow-auto uk-flex-1 uk-flex uk-flex-column">
      {PluginComponent ?
        <Suspense fallback={<Spinner ratio={2} position="center" />}>
          <ErrorBoundary>
            <PluginComponent pathParams={getPathParams()} searchParams={getSearchParams()} />
          </ErrorBoundary>
        </Suspense> :
        <div>Unknown plugin: {pluginName}</div>
      }
    </div>
  );
}

export default PluginContainer;
