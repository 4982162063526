export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  /** A GraphQL type representing the Credit value (the actual value is a float). */
  Credit: any;
  /** A GraphQL type representing the Void value (the actual value is null). */
  Void: any;
};

/** Type representing the relevant data relating to Cloud Credits  */
export type AccountingCreditRecord = Node & {
   __typename: 'AccountingCreditRecord';
  id: Scalars['ID'];
  /**  Currently available credits in the purchase */
  balance: Scalars['Credit'];
  /**  Original credits purchased  */
  quantity: Scalars['Credit'];
  /**  Date/Time credits were purchased  */
  purchaseTime?: Maybe<Scalars['DateTime']>;
  /**  Date/Time credits will expire */
  expiration?: Maybe<Scalars['DateTime']>;
  /**  Are these trial credits */
  isTrial?: Maybe<Scalars['Boolean']>;
  soldToId?: Maybe<Scalars['String']>;
};

/** Type representing the the Cloud Credits used for a particular job */
export type AccountingDebitReport = Node & {
   __typename: 'AccountingDebitReport';
  id: Scalars['ID'];
  /**  The time of the first debit operation recorded  */
  startTime?: Maybe<Scalars['DateTime']>;
  /**  The time of the last debit operation recorded  */
  endTime?: Maybe<Scalars['DateTime']>;
  /**  Total debited credits  */
  amount: Scalars['Credit'];
};

/** Type representing the Job Manager compute job. */
export type ComputeJob = Node & {
   __typename: 'ComputeJob';
  /** Unique job identifier. */
  id: Scalars['ID'];
  /** Name of the job, either automatically generated or specified by the user. */
  name: Scalars['String'];
  /** Current job status. */
  status: ComputeJobStatus;
  /** Submission template used for the job. */
  submissionTemplate?: Maybe<SubmissionTemplate>;
  /** Resources requested for the job. */
  requestedResources?: Maybe<ComputeJobRequestedResources>;
  /** Resource group (cluster) related submission ID. */
  submissionId?: Maybe<Scalars['String']>;
  /** Job submission date and time (ISO format). */
  submissionTime?: Maybe<Scalars['DateTime']>;
  /** Owner of Job */
  ownerId?: Maybe<Scalars['String']>;
  /** List of tasks that are part of the job. */
  tasks: Array<ComputeTask>;
  owner?: Maybe<User>;
  debitReport?: Maybe<AccountingDebitReport>;
};

/** Type representing the resources requested for the compute job. */
export type ComputeJobRequestedResources = {
   __typename: 'ComputeJobRequestedResources';
  /** Number of compute slots (e.g. processors, cores, etc.) requested. */
  computeSlots: Scalars['Int'];
  /** Reqeusted maximum execution time (AKA walltime) for the job in seconds. */
  maxExecutionTime?: Maybe<Scalars['Float']>;
  /** Version of the STAR-CCM+ used. */
  starccmpVersion?: Maybe<Scalars['String']>;
};

/** Input type representing the resources requested for the compute job. */
export type ComputeJobRequestedResourcesInput = {
  /** Number of compute slots (e.g. processors, cores, etc.) requested. */
  computeSlots?: Maybe<Scalars['Int']>;
  /** Reqeusted maximum execution time (AKA walltime) for the job in seconds. */
  maxExecutionTime?: Maybe<Scalars['Float']>;
  /** Requested STAR-CCM+ version to be used. */
  starccmpVersion?: Maybe<Scalars['String']>;
};

/** Enum representing the job status. */
export enum ComputeJobStatus {
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Running = 'RUNNING',
  Done = 'DONE',
  Interrupted = 'INTERRUPTED',
  Failed = 'FAILED'
}

/** Type representing the Job Manager compute job task. */
export type ComputeTask = Node & {
   __typename: 'ComputeTask';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the task, usually the file name. */
  name: Scalars['String'];
  /** Parent task (if this is a sub-task). */
  parent?: Maybe<ComputeTask>;
  /** Current task status. */
  status: ComputeTaskStatus;
  /** Status representing rollup of Job and Task status values. */
  unifiedStatus: ComputeTaskUnifiedStatus;
  /** Application type name. */
  application?: Maybe<Scalars['String']>;
  /** Required Resources */
  requiredResources?: Maybe<ComputeTaskRequiredResources>;
  /** Running application attributes/state. */
  appAttributes?: Maybe<ComputeTaskAppAttributes>;
  /** URN to the task input file repository. */
  inputFileRepositoryUrn?: Maybe<Scalars['String']>;
  /** URN to the task output file repository. */
  outputFileRepositoryUrn?: Maybe<Scalars['String']>;
  /** Task process console output log, start is 0-based character 'start' position */
  output: Scalars['String'];
  /** Paginated console output log */
  outputLines: StringsConnection;
  inputFileRepository?: Maybe<FileRepository>;
  outputFileRepository?: Maybe<FileRepository>;
};


/** Type representing the Job Manager compute job task. */
export type ComputeTaskOutputArgs = {
  start: Scalars['Int'];
};


/** Type representing the Job Manager compute job task. */
export type ComputeTaskOutputLinesArgs = {
  input: ConnectionInput;
};

/** Type representing task application attributes / state. */
export type ComputeTaskAppAttributes = {
   __typename: 'ComputeTaskAppAttributes';
  /** Application end-point URL. */
  url?: Maybe<Scalars['String']>;
  /** Product version of the task application. */
  productVersion?: Maybe<Scalars['String']>;
};

/** Type representing ComputeTask required resources specification. */
export type ComputeTaskRequiredResources = {
   __typename: 'ComputeTaskRequiredResources';
  /** Number of compute slots (e.g. processors, cores, etc.) required. */
  computeSlots?: Maybe<Scalars['Int']>;
};

/** Input type representing the resources required to run the task. */
export type ComputeTaskRequiredResourcesInput = {
  /** Number of compute resources (slots/processors) required. */
  computeSlots: Scalars['Int'];
};

/** Enum representing the task status. */
export enum ComputeTaskStatus {
  Pending = 'PENDING',
  Starting = 'STARTING',
  Executing = 'EXECUTING',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Terminating = 'TERMINATING',
  Terminated = 'TERMINATED',
  StartFailed = 'START_FAILED',
  LaunchPending = 'LAUNCH_PENDING'
}

/**
 * Enum representing the task 'unified' status. Unified status is a combined job and task
 * status values into one status (better user representation of the overall task status).
 */
export enum ComputeTaskUnifiedStatus {
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Starting = 'STARTING',
  Executing = 'EXECUTING',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Terminating = 'TERMINATING',
  Terminated = 'TERMINATED',
  StartFailed = 'START_FAILED',
  Interrupted = 'INTERRUPTED'
}

/** Input for all connections */
export type ConnectionInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** Input type for the `connectSimulation` mutation. */
export type ConnectSimulationInput = {
  taskId: Scalars['ID'];
};

/** Result returned from the `connectSimulation` mutation. */
export type ConnectSimulationResult = {
   __typename: 'ConnectSimulationResult';
  /** Simulation from the connected starccm+ server. */
  simulation: Simulation;
};

/** Input type for the `createComputeJob` mutation. */
export type CreateComputeJobInput = {
  /** Optional Compute Job name. */
  name?: Maybe<Scalars['String']>;
  /** Submission template for the Compute Job submission. */
  submissionTemplateId: Scalars['ID'];
  /** Resources requestd for the compute job */
  requestedResources?: Maybe<ComputeJobRequestedResourcesInput>;
};

/** Result returned from the `createComputeJob` mutation. */
export type CreateComputeJobResult = {
   __typename: 'CreateComputeJobResult';
  /** Created compute job if successfull. */
  job: ComputeJob;
};

/** Input type for the `createComputeTask` mutation. */
export type CreateComputeTaskInput = {
  /** ID of the job to create this taks for. */
  computeJobId: Scalars['ID'];
  /** Optional Compute Task name. */
  name?: Maybe<Scalars['String']>;
  /** Name of the application to be launched for the new task. */
  application: Scalars['String'];
  /** Resources required to run the new task. */
  requiredResources?: Maybe<ComputeTaskRequiredResourcesInput>;
  /** Optional list of application command line options. */
  cmdOptions?: Maybe<Array<Scalars['String']>>;
  /** If true the application is immediately launched when the job is submitted. */
  launch?: Maybe<Scalars['Boolean']>;
};

/** Result returned from the `createComputeTask` mutation. */
export type CreateComputeTaskResult = {
   __typename: 'CreateComputeTaskResult';
  /** Created compute job if successfull. */
  task: ComputeTask;
};

/** Input type for the `createViewObjectRenderer` mutation. */
export type CreateViewObjectRendererInput = {
  /** Renderer/canvas width. */
  width: Scalars['Int'];
  /** Renderer/canvas height. */
  height: Scalars['Int'];
  /** ID of the View object (e.g. scene or plot) to create the renderer for. */
  viewObjectId: Scalars['ID'];
};

/** Result of the `createViewObjectRenderer` mutation. */
export type CreateViewObjectRendererResult = {
   __typename: 'CreateViewObjectRendererResult';
  /** Created renderer if successful. */
  renderer: ViewObjectRenderer;
};



/** Input type for `deleteNode` mutation argument. */
export type DeleteNodeInput = {
  /** ID of the Node to delete. */
  id: Scalars['ID'];
};

/** Result returned from the `deleteNode` mutation. */
export type DeleteNodeResult = {
   __typename: 'DeleteNodeResult';
  _?: Maybe<Scalars['Void']>;
};

/** Input type for the `disconnectSimulation` mutation. */
export type DisconnectSimulationInput = {
  simulationId: Scalars['ID'];
};

/** Result returned from the `disconnectSimulation` mutation. */
export type DisconnectSimulationResult = {
   __typename: 'DisconnectSimulationResult';
  _?: Maybe<Scalars['Void']>;
};

/** Interface representing the file repository. */
export type FileRepository = {
  /** File repository Node id. */
  id: Scalars['ID'];
  /** URN representing file repository backing store resource. */
  urn: Scalars['String'];
  /** Repository status (see the enum description). */
  status: FileRepositoryStatus;
};

/** Enum representing the file repository status. */
export enum FileRepositoryStatus {
  /** Repository not used yet. */
  Pending = 'PENDING',
  /** All operations finished successfully. */
  Ready = 'READY',
  /** File operations in progress. */
  Busy = 'BUSY',
  /** File operations failed. Content unknown or potentially inconsistent. */
  Failed = 'FAILED'
}

/** Type representing the "File Repository Serivce" (FRS) file repository backed by AWS S3 storage. */
export type FrsFileRepository = Node & FileRepository & {
   __typename: 'FrsFileRepository';
  /** Unique file repository identifier. */
  id: Scalars['ID'];
  /** URN representing file repository storage resource. */
  urn: Scalars['String'];
  /** Repository status (see the enum description). */
  status: FileRepositoryStatus;
  /** Flat list of files stored in the repository (in output root and all sub-folders). */
  files: Array<FrsFileRepositoryFile>;
};

/** Type representing the file in the file repository. */
export type FrsFileRepositoryFile = {
   __typename: 'FrsFileRepositoryFile';
  /**
   * File path, name, and extension, relative to the repository root folder.
   * Filename is in the POSIX format (folders separated by `/`).
   */
  filename: Scalars['String'];
  /** File size. */
  size?: Maybe<Scalars['Float']>;
};

/**
 * Input type for the `issueFrsFileRepositoryUploadTickets` and `issueFrsFileRepositoryDownloadTickets`
 * mutations.
 */
export type IssueFrsFileRepositoryTicketsInput = {
  /** Id of the file repository for the file operations. */
  repositoryId: Scalars['ID'];
  /**
   * List of filenames (with the path relative to the repository root folder) to issue the tickets for.
   * Filenames are in the POSIX format (folders separated by `/`).
   */
  filenames: Array<Scalars['String']>;
};

/**
 * Type representing the result of the `issueFrsFileRepositoryUploadTickets` and
 * `issueFrsFileRepositoryDownloadTickets` mutations.
 */
export type IssueFrsFileRepositoryTicketsResult = {
   __typename: 'IssueFrsFileRepositoryTicketsResult';
  serviceUrl: Scalars['String'];
  bucket: Scalars['String'];
  accessKey: Scalars['String'];
  secretAccessKey: Scalars['String'];
  sessionToken: Scalars['String'];
  expiration?: Maybe<Scalars['DateTime']>;
  prefix: Scalars['String'];
};

/** Interface for type and field annotations. */
export type MetaAnnotation = {
  /** Annotation name. */
  name: Scalars['String'];
};

/** Type representing API type field description. */
export type MetaField = {
   __typename: 'MetaField';
  /** Name of the field this MetaField represents. */
  name: Scalars['String'];
  /** Field type. */
  type?: Maybe<Scalars['String']>;
  /** List of field annotations. */
  annotations?: Maybe<Array<MetaAnnotation>>;
};

/** Type representing additional API object type description. */
export type MetaType = {
   __typename: 'MetaType';
  /** Name of the type this MetaType describes. */
  name: Scalars['String'];
  /** List of field descriptors for the API type fields. */
  fields?: Maybe<Array<MetaField>>;
  /** List of type annotations. */
  annotations?: Maybe<Array<MetaAnnotation>>;
};

export type Mutation = {
   __typename: 'Mutation';
  /** Create new Compute Job. */
  createComputeJob?: Maybe<CreateComputeJobResult>;
  /** Create new Compute Task for the Compute Job. */
  createComputeTask?: Maybe<CreateComputeTaskResult>;
  /** Submit Compute Job to be executed. */
  submitComputeJob?: Maybe<SubmitComputeJobResult>;
  /** Terminate the Compute Task (and processes/application it represents). Does not delete the task. */
  terminateComputeTask?: Maybe<TerminateComputeTaskResult>;
  /** Terminates all running Compute Job tasks and finishes the Compute Job (moves it to `DONE` state). */
  terminateComputeJob?: Maybe<TerminateComputeJobResult>;
  /** Delete the Node with the given ID. Can be used to delete the Node types e.g. ViewObjectRenderer. */
  deleteNode?: Maybe<DeleteNodeResult>;
  /** Connect to simulation running as a ComputeTask. */
  connectSimulation?: Maybe<ConnectSimulationResult>;
  /** Disconnect simulation. Returns null. */
  disconnectSimulation?: Maybe<DisconnectSimulationResult>;
  /** Save and Shutdown Simulation. Returns null. */
  saveAndCloseSimulation?: Maybe<SaveAndCloseSimulationResult>;
  /** Creates view object renderer for the given view object. */
  createViewObjectRenderer?: Maybe<CreateViewObjectRendererResult>;
  /** Updates view object renderer from the given parameters. */
  updateViewObjectRenderer?: Maybe<UpdateViewObjectRendererResult>;
  /** Sends user interaction mouse event to the view object renderer. Returns null. */
  sendViewObjectRendererMouseEvent?: Maybe<SendViewObjectRendererMouseEventResult>;
  /** Resets the camera of the view object renderer to show all scene content. */
  resetViewObjectRendererCamera?: Maybe<ResetViewObjectRendererCameraResult>;
  /** Request tickets for the files uplaod. */
  issueFrsFileRepositoryUploadTickets: IssueFrsFileRepositoryTicketsResult;
  /** Request tickets for the files download. */
  issueFrsFileRepositoryDownloadTickets: IssueFrsFileRepositoryTicketsResult;
};


export type MutationCreateComputeJobArgs = {
  input: CreateComputeJobInput;
};


export type MutationCreateComputeTaskArgs = {
  input: CreateComputeTaskInput;
};


export type MutationSubmitComputeJobArgs = {
  input: SubmitComputeJobInput;
};


export type MutationTerminateComputeTaskArgs = {
  input: TerminateComputeTaskInput;
};


export type MutationTerminateComputeJobArgs = {
  input: TerminateComputeJobInput;
};


export type MutationDeleteNodeArgs = {
  input: DeleteNodeInput;
};


export type MutationConnectSimulationArgs = {
  input: ConnectSimulationInput;
};


export type MutationDisconnectSimulationArgs = {
  input: DisconnectSimulationInput;
};


export type MutationSaveAndCloseSimulationArgs = {
  input: SaveAndCloseSimulationInput;
};


export type MutationCreateViewObjectRendererArgs = {
  input: CreateViewObjectRendererInput;
};


export type MutationUpdateViewObjectRendererArgs = {
  input: UpdateViewObjectRendererInput;
};


export type MutationSendViewObjectRendererMouseEventArgs = {
  input: SendViewObjectRendererMouseEventInput;
};


export type MutationResetViewObjectRendererCameraArgs = {
  input: ResetViewObjectRendererCameraInput;
};


export type MutationIssueFrsFileRepositoryUploadTicketsArgs = {
  input: IssueFrsFileRepositoryTicketsInput;
};


export type MutationIssueFrsFileRepositoryDownloadTicketsArgs = {
  input: IssueFrsFileRepositoryTicketsInput;
};

/**
 * Interface implemented by all nodes/entities that can be identified by unique id.
 * Nodes can be re-fetched using the id.
 */
export type Node = {
  /** Unique node identifier. You can use it to re-fetch the node using {node(id: ID)} query. */
  id: Scalars['ID'];
};

/** Type for pagination information  */
export type PageInfo = {
   __typename: 'PageInfo';
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
  endCursor: Scalars['String'];
};

/** Type representing the plot. */
export type Plot = Node & {
   __typename: 'Plot';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Plot name. */
  name: Scalars['String'];
};

export type Query = {
   __typename: 'Query';
  /** List of meta types - API type descriptors. */
  metaTypes?: Maybe<Array<MetaType>>;
  /** Retrieves the meta-type describing the type with the given name. */
  metaType?: Maybe<MetaType>;
  /** Fetch the node/entity by id. */
  node?: Maybe<Node>;
  /** The currently logged in user. */
  currentUser?: Maybe<User>;
  /** The user workspace. */
  workspace?: Maybe<Workspace>;
  /** Get the `FrsFileRepository` instance from its URN. */
  frsFileRepository?: Maybe<FrsFileRepository>;
  /**  The credit balance for this user  */
  accountingUserBalance: Scalars['Credit'];
  /**  Internal - linkage to ComputeJob  */
  _debitReport?: Maybe<AccountingDebitReport>;
};


export type QueryMetaTypeArgs = {
  name: Scalars['String'];
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryFrsFileRepositoryArgs = {
  urn: Scalars['String'];
};


export type Query_DebitReportArgs = {
  jobId: Scalars['ID'];
};

/** Type representing field annotation denoting read-only field. */
export type ReadOnlyMetaAnnotation = MetaAnnotation & {
   __typename: 'ReadOnlyMetaAnnotation';
  /** Annotation name. */
  name: Scalars['String'];
};

/** Enum representing ViewObjectRenderer mouse event button. */
export enum RendererMouseEventButton {
  NoButton = 'NO_BUTTON',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT'
}

/** Enum representing ViewObjectRenderer mouse event type. */
export enum RendererMouseEventType {
  MouseClicked = 'MOUSE_CLICKED',
  MousePressed = 'MOUSE_PRESSED',
  MouseReleased = 'MOUSE_RELEASED',
  MouseEntered = 'MOUSE_ENTERED',
  MouseExited = 'MOUSE_EXITED',
  MouseDragged = 'MOUSE_DRAGGED',
  MouseMoved = 'MOUSE_MOVED',
  KeyPressed = 'KEY_PRESSED',
  MouseWheelMoved = 'MOUSE_WHEEL_MOVED'
}

/** Input type for the `resetViewObjectRendererCamera` mutation. */
export type ResetViewObjectRendererCameraInput = {
  /** ID of the ViewObjectRenderer for which the camera should be reset. */
  rendererId: Scalars['ID'];
};

/** Result of the `resetViewObjectRendererCamera` mutation. */
export type ResetViewObjectRendererCameraResult = {
   __typename: 'ResetViewObjectRendererCameraResult';
  /** Reset ViewObjectRenderer if successful. */
  renderer: ViewObjectRenderer;
};

/** Input type for the `saveAndCloseSimulation` mutation. */
export type SaveAndCloseSimulationInput = {
  simulationId: Scalars['ID'];
};

/** Result returned from the `saveAndCloseSimulation` mutation. */
export type SaveAndCloseSimulationResult = {
   __typename: 'SaveAndCloseSimulationResult';
  _?: Maybe<Scalars['Void']>;
};

/** Type representing the scene. */
export type Scene = Node & {
   __typename: 'Scene';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Scene name. */
  name: Scalars['String'];
};

/** Input type for the `sendViewObjectRendererMouseEvent` mutation. */
export type SendViewObjectRendererMouseEventInput = {
  /** ID of the view object renderer that is the target of the interraction. */
  rendererId: Scalars['ID'];
  /** Mouse event type. */
  eventType: RendererMouseEventType;
  /** Mouse button involved. */
  button: RendererMouseEventButton;
  x: Scalars['Int'];
  y: Scalars['Int'];
  ctrl: Scalars['Boolean'];
  shift: Scalars['Boolean'];
  alt: Scalars['Boolean'];
  wheelRotation: Scalars['Int'];
};

/** Result of the `sendViewObjectRendererMouseEvent` mutation. */
export type SendViewObjectRendererMouseEventResult = {
   __typename: 'SendViewObjectRendererMouseEventResult';
  _?: Maybe<Scalars['Void']>;
};

/** Main type representing the STAR-CCM+ simulation. */
export type Simulation = Node & {
   __typename: 'Simulation';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Simulation name. */
  name: Scalars['String'];
  /** List of scenes in the simulation. */
  scenes?: Maybe<Array<Maybe<Scene>>>;
  /** List of plots in the simulation. */
  plots?: Maybe<Array<Maybe<Plot>>>;
};

/** Type for string pagination edges */
export type StringEdge = {
   __typename: 'StringEdge';
  node: Scalars['String'];
  cursor: Scalars['String'];
};

/** Type for string pagination connections */
export type StringsConnection = {
   __typename: 'StringsConnection';
  edges: Array<StringEdge>;
  nodes: Array<Scalars['String']>;
  pageInfo: PageInfo;
};

/**
 * Type representing submission template used for the new `ComputeJob` creation.
 * Submission specifies the "blueprint" for the resources requested for the compute job.
 */
export type SubmissionTemplate = Node & {
   __typename: 'SubmissionTemplate';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the submission template used e.g. in the selection list. */
  name: Scalars['String'];
  /** More detail description of the submission template purpose and attributes. */
  description?: Maybe<Scalars['String']>;
  /** Supplied by Credit Management Service */
  hourlyCreditRate: Scalars['Float'];
};

/** Input type for the `submitComputeJob` mutation. */
export type SubmitComputeJobInput = {
  /** ID of the job to submit. */
  computeJobId: Scalars['ID'];
};

/** Result returned from the `submitComputeJob` mutation. */
export type SubmitComputeJobResult = {
   __typename: 'SubmitComputeJobResult';
  /** Submitted compute job if successfull. */
  job: ComputeJob;
};

/** Input type for the `terminateComputeJob` mutation. */
export type TerminateComputeJobInput = {
  /** ID of the ComputeJob to terminate. */
  computeJobId: Scalars['ID'];
};

/** Result returned from the `terminateComputeJob` mutation. */
export type TerminateComputeJobResult = {
   __typename: 'TerminateComputeJobResult';
  /** Terminated compute job. */
  job: ComputeJob;
};

/** Input type for the `terminateComputeTask` mutation. */
export type TerminateComputeTaskInput = {
  /** ID of the ComputeTask to terminate. */
  computeTaskId: Scalars['ID'];
};

/** Result returned from the `terminateComputeTask` mutation. */
export type TerminateComputeTaskResult = {
   __typename: 'TerminateComputeTaskResult';
  /** Terminated compute task. */
  task: ComputeTask;
};

/** Input type for the `updateViewObjectRenderer` mutation. */
export type UpdateViewObjectRendererInput = {
  /** ID of the ViewObjectRenderer to update. */
  rendererId: Scalars['ID'];
  /** New renderer/canvas width. */
  width: Scalars['Int'];
  /** New renderer/canvas height. */
  height: Scalars['Int'];
};

/** Result of the `updateViewObjectRenderer` mutation. */
export type UpdateViewObjectRendererResult = {
   __typename: 'UpdateViewObjectRendererResult';
  /** Updated ViewObjectRenderer if successful. */
  renderer: ViewObjectRenderer;
};

/** Type representing the User */
export type User = Node & {
   __typename: 'User';
  /** Unique User identifier. */
  id: Scalars['ID'];
  /** User login name. */
  userName: Scalars['String'];
  /** User display name. */
  displayName?: Maybe<Scalars['String']>;
  /** User email address. */
  email?: Maybe<Scalars['String']>;
};

/** Type representing renderer for view object */
export type ViewObjectRenderer = Node & {
   __typename: 'ViewObjectRenderer';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Renderer/canvas width. */
  width: Scalars['Int'];
  /** Renderer/canvas height. */
  height: Scalars['Int'];
  /** View object this renderer was created for (e.g. scene or plot). */
  viewObjectId: Scalars['ID'];
  /** Endpoint (URL) of the streaming Renderer Proxy. */
  streamEndpoint: Scalars['String'];
};


/** Main type representing the root Workspace for the user. */
export type Workspace = Node & {
   __typename: 'Workspace';
  /** Unique workspace identifier. You can re-fetch the workspace using {node(id: ID)} query. */
  id: Scalars['ID'];
  /** List of jobs in the workspace. */
  jobs: Array<ComputeJob>;
  /** List of the job submission templates. */
  submissionTemplates: Array<SubmissionTemplate>;
};

