import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Translate = (key: string, values?: Record<string, any>, asComponent?: boolean) => string;

export default function useTranslator(): Translate {
  const { t } = useTranslation();
  const translate = useCallback((key: string, values?: Record<string, any>, asComponent?: boolean) => {
    if (asComponent) {
      // @ts-ignore
      return <Trans i18nKey={key} values={values} />;
    } else {
      return t(key, values);
    }
  }, [t]);
  return translate;
}
