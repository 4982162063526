import React, { useState, useEffect } from 'react';
import { useAppContext, useTranslator } from 'core/hooks';
import { navigateUp, getPathParams } from 'core/utils';
import { ReactComponent as BackButtonIcon } from 'afx/image/cmdBackLHN16.svg';

function shouldShowBackButton() {
  return getPathParams().length > 1;
}

function BackButton() {
  const { history } = useAppContext();
  const [showBackButton, setShowBackButton] = useState<boolean>(shouldShowBackButton());
  const t = useTranslator();

  useEffect(() => {
    return history.listen(() => {
      setShowBackButton(shouldShowBackButton());
    });
  }, [history]);

  function goBack() {
    navigateUp();
  }

  if (!showBackButton) {
    return null;
  }

  return (
    <button
      type="button"
      className="uk-button uk-button-text"
      onClick={goBack}>
      <BackButtonIcon width={20} height={20} data-uk-tooltip={t('core.label.back')}/>
    </button>
  );
}

export default BackButton;