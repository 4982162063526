export { default as ErrorBoundary } from "./ErrorBoundary";
export { default as ErrorInfo } from "./ErrorInfo";
export { default as Navigation } from "./Navigation";
export { default as Spinner } from "./Spinner";
export { default as Unauthorized } from "./Unauthorized";
export { default as Header } from "./Header";
export { default as Footer } from "./Footer";
export * from "./Modal";
export { default as Modal } from "./Modal";
export { default as Logout } from "./Logout";
export { default as TimeSkewed} from "./TimeSkewed"
export * from "./PluginContainer";
export { default as ModalSpinner } from "./ModalSpinner";
export { default as FileSelector } from "./FileSelector";
export { default as BackButton } from "./BackButton";
export { default as HomeButton } from "./HomeButton";

export const smallWidthMediaQuery = '(max-width: 520px)';
export const mediumWidthMediaQuery = '(max-width: 800px)';
